import React from "react";

const RestrictedTimesNotice = ({ restrictedTimes = [], dayOfTheWeek = "" }) => {
  return (
    <div>
      <p className="text-red-500">
        You will not be able to attend or cancel attendance between{" "}
        {new Date(
          `${new Date().toLocaleString().split(",")[0]}, ${restrictedTimes[0]}`
        ).toLocaleTimeString("en-US", {
          hour12: true,
          minute: "2-digit",
          hour: "numeric",
        })}{" "}
        and{" "}
        {new Date(
          `${new Date().toLocaleString().split(",")[0]}, ${restrictedTimes[1]}`
        ).toLocaleTimeString("en-US", {
          hour12: true,
          minute: "2-digit",
          hour: "numeric",
        })}{" "}
        on {dayOfTheWeek}s.
      </p>
      <p className="text-gray-500 text-sm mt-3">
        If the current time is outside this range, please refresh the page and
        try again.
      </p>
    </div>
  );
};
export default RestrictedTimesNotice;
