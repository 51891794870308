import { useState } from "react";
import DownArrow from "../svg/DownArrow";
import ManageAdminDeleting from "./ManageAdminDeleting";
import ManageTeacherEditing from "./ManageTeacherEditing";

const ManageInterventionPermissions = ({currentUser = {}}) => {
  const [openDropdown, setOpendropdown] = useState(false);

  return (
    <div className="flex gap-4 items-start justify-start p-2 my-4 border-t border-gray-300 w-full">
      <button
        className="w-4 h-4 mt-3"
        onClick={() => {
          setOpendropdown(!openDropdown);
        }}
      >
        <DownArrow className={`${openDropdown ? "" : "rotate-[270deg]"}`} />
      </button>
      <div>
        <h3 className="text-gray-800 text-3xl font-medium">
          Intervention Permissions
        </h3>
        <p className="text-md font-light mb-4 text-gray-500">
          Choose whether or not admins can delete <i>any</i> intervention, and
          manage intervention editing permissions for teachers and admins.
        </p>
        <div
          className={`flex flex-col items-start gap-10 ${
            openDropdown ? "visible" : "hidden"
          }`}
        >
          <ManageAdminDeleting currentUser={currentUser} />
          <ManageTeacherEditing currentUser={currentUser} />
        </div>
      </div>
    </div>
  );
};
export default ManageInterventionPermissions;
