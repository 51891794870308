import React, { useState, useEffect, useCallback } from "react";

const RestrictTeacherAccess = ({ currentUser = {}, ...props }) => {
  const [changeMade, setchangeMade] = useState(false);
  const [restrictTeacherAccess, setrestrictTeacherAccess] = useState(false);

  const getSchoolSettings = useCallback(async () => {
    if (currentUser?.token) {
      try {
        const data = await fetch(
          `${process.env.REACT_APP_API_URL}school/school-settings`,
          {
            headers: {
              Authorization: "Bearer " + currentUser?.token,
            },
          }
        );
        const res = await data.json();
        console.log(res)
        if (!res.error) {
          setrestrictTeacherAccess(res?.restrict_teacher_access);
        }
      } catch (error) {
        console.error("error getting signup times: ", error);
      }
    }
  }, [currentUser?.token]);

  async function saveRestrictTeacherAccess(value) {
    if (!currentUser?.token) return;
    try {
      const data = await fetch(
        `${process.env.REACT_APP_API_URL}school/restrict-teacher-access/${currentUser?.user?.school}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + currentUser?.token,
          },
          body: JSON.stringify({
            restrict_teacher_access: value,
          }),
        }
      );
      const res = await data.json();
      setchangeMade(false);
    } catch (error) {
      console.error("error getting signup times: ", error);
    }
  }

  useEffect(() => {
    getSchoolSettings();
  }, [getSchoolSettings]);
  return (
    <div className="mb-4 w-full">
      <label className="font-semibold text-gray-700">
        Restrict Teacher's Access during restricted times?
      </label>
      <input
        type="checkbox"
        id="restrict-teacher"
        className="w-5 h-5 ml-2 align-text-bottom"
        checked={restrictTeacherAccess}
        onChange={(e) => {
          setrestrictTeacherAccess(e.target.checked)
          setchangeMade(!changeMade);
        }}
      />
      <button
      onClick={() => {
        saveRestrictTeacherAccess(restrictTeacherAccess)
      }}
        disabled={!changeMade}
        type="button"
        className=" ml-4 rounded-md shadow-md text-white px-6 py-2 disabled:bg-gray-300 bg-blueprimary-500 hover:bg-blueprimary-600"
      >
        Save
      </button>
      <span className="mt-2 text-sm text-gray-400 block self-start">
        This will prevent teachers from being able to create, edit, or delete
        interventions during a restricted time-frame.
      </span>
    </div>
  );
};
export default RestrictTeacherAccess;
