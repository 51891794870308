import React, { useRef, useState, useEffect } from "react";
import SearchBar from "../SearchBar";
import CloseSVG from "../svg/CloseSVG";

const ExcludedStudents = ({
  students,
  setExcludedStudents,
  setChangesMade,
}) => {
  const [filteredStudents, setFilteredStudents] = useState([]);
  const ref = useRef(null);

  useEffect(() => {
    setFilteredStudents(students);
  }, [students]);

  return (
    <div className="flex flex-col gap-4 mb-4">
      <div className="flex gap-2 items-end justify-between">
        <SearchBar
          className="w-fit attendees-searchbar rounded-md shadow-md"
          placeholder="Search students..."
          searchFields={["first_name", "last_name", "email"]}
          inputArray={students}
          updateArray={(newArray) => setFilteredStudents([...newArray])}
          inputRef={ref}
          id="excluded-students-searchbar"
        />
        <h3 className="font-semibold text-gray-800">Excluded Students</h3>
      </div>
      <table className="settings-students-list__grid overflow-y-scroll scrollbar grid  border-grayprimary-500 border-b-transparent border shadow-md rounded-md overflow-hidden w-full">
        <thead className=" contents subgrid">
          <tr className="text-lg contents subgrid">
            <th className="text-gray-800 border-grayprimary-500 border-b-2 p-2.5 grid place-items-start">
              Email
            </th>
            <th className="text-gray-800 border-grayprimary-500 border-b-2 p-2.5 grid place-items-start">
              Name
            </th>
            <th className="text-gray-800 border-grayprimary-500 border-b-2 p-2.5 place-items-start">
              Grade
            </th>
            <th className="text-gray-800 border-grayprimary-500 border-b-2 p-2.5 place-items-start">
              Remove
            </th>
          </tr>
        </thead>
        <tbody className="text-sm pt-4 contents subgrid">
          {filteredStudents
            ?.sort((a, b) =>
              a.last_name.toLowerCase() > b.last_name.toLowerCase() ? 1 : -1
            )
            .map((user, index) => (
              <tr key={index} className="contents subgrid">
                <td className="border-grayprimary-500 border-b p-2.5">
                  {user.email}
                </td>
                <td className="border-grayprimary-500 border-b p-2.5">
                  {user.last_name}, {user.first_name}
                </td>
                <td className="border-grayprimary-500 border-b p-2.5 text-center flex items-center justify-center">
                  {user.grade_level && user.grade_level !== -1
                    ? user.grade_level
                    : "-"}
                </td>
                <td className="border-grayprimary-500 border-b p-2.5 text-center flex items-center justify-center">
                  <button
                    type="button"
                    onClick={() => {
                      setExcludedStudents((prev) => {
                        const temp = [...prev];
                        temp.splice(index, 1);
                        return [...temp];
                      });
                      setChangesMade(true);
                      if (ref.current.value?.length > 0) {
                        ref.current.value = "";
                      }
                    }}
                  >
                    <CloseSVG className="text-redprimary-500" />
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
export default ExcludedStudents;
