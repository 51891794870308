import React from "react";

/**
 *
 * @param {className} className allows for special formatting
 * @returns
 */
const EyeSVG = ({ className = "" }) => {
  return (
    <svg
      className="w-5 h-5"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00179 6.95312C7.14645 6.95312 6.45044 7.64713 6.45044 8.4998C6.45044 9.35646 7.14645 10.0531 8.00179 10.0531C8.85712 10.0531 9.55245 9.35646 9.55245 8.4998C9.55245 7.64713 8.85712 6.95312 8.00179 6.95312Z"
        fill="#3E6DB1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00179 11.0529C6.59509 11.0529 5.45043 9.90756 5.45043 8.49956C5.45043 7.09492 6.59509 5.95292 8.00179 5.95292C9.40845 5.95292 10.5525 7.09492 10.5525 8.49956C10.5525 9.90756 9.40845 11.0529 8.00179 11.0529ZM14.3065 8.36822C12.9778 5.27758 10.6198 3.43225 7.99845 3.43225C5.37843 3.43225 3.02176 5.27758 1.69376 8.36822C1.65709 8.45222 1.65709 8.54689 1.69376 8.63089C3.02109 11.7202 5.37776 13.5656 7.99779 13.5669H8.00179C10.6218 13.5656 12.9785 11.7202 14.3065 8.63089C14.3425 8.54689 14.3425 8.45222 14.3065 8.36822Z"
        fill="#3E6DB1"
      />
    </svg>
  );
};
export default EyeSVG;
