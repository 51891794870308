import React, { Component, useEffect, useRef, useState } from "react";
import EyeSVG from "../components/svg/EyeSVG";

/**
 * TextInput Component
 * Is a generally stylized and reusable input
 * @param id Id for the generated input field.  Applied to input element itself
 * @param className Additional classes to add to the overall wrapper of component
 * @param type Input type passed into input element
 * @param pattern Input validation pattern to be passed onto input field
 * @param onChange Function that gets sent the event every time input field is changed
 * @param label Text to be displayed above input
 * @param errorMessage Text to displayed below input field if there is an error
 * @param hasError Boolean value to determine whether input should be styled to have error and if error message should be displayed
 * @param setHasError Function that is called once input is validated
 * @param defaultValue Value to which the input should be initialized
 * @param icon Icon to be showed before input.  Should be an element rather than a url
 * @param minLength Minimum length of input text to be considered valid
 * @param required Whether or not the input should be marked valid in a form
 * @param onBlur Function that is passed the event whenever onBlur is called by the input
 * @param name Name to be applied to the input element
 * @param min Minimum number value passed onto input element
 * @param max Maximum number value passed onto input element
 */
const TextInput = ({
  id = "",
  className = "",
  type = "text",
  pattern = ".*",
  onChange = (e) => {},
  label = "",
  errorMessage = "",
  hasError = false,
  setHasError = (e) => {},
  defaultValue = "",
  placeholder = "",
  icon = null,
  minLength = "",
  required = true,
  onBlur = () => {},
  name = "",
  min = "",
  max = "",
}) => {
  const [hidePassword, setHidePassword] = useState(true);
  const [value, setValue] = useState(defaultValue);
  const input = useRef();
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  return (
    <label className={className + (hasError ? " invalid " : " valid ")}>
      <span className="text-tssBlack-500 font-normal text-sm">{label}</span>
      <div
        className={`mt-[6px] border-tssGray-500 border-[1.25px] w-full rounded-lg  ${className} relative`}
      >
        <span className="">{icon}</span>
        <input
          id={id}
          className={
            "font-normal text-sm placeholder-text-tssGray-600 text-tssBlack-500 w-full px-3 py-[0.5rem] rounded-lg"
          }
          type={hidePassword ? type : "text"}
          pattern={pattern}
          placeholder={placeholder}
          name={name}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e);
          }}
          onBlur={(e) => {
            setValue(e.target.value);
            setHasError(!e.target.validity.valid);
            // checkValidityOnBlur(input, setValidity, setHasError);
            onBlur(e);
          }}
          ref={input}
          value={value}
          required={required}
          minLength={minLength}
          min={min}
          max={max}
          // style={{
          //   padding: icon != null ? "1em 0 1em 0" : "1em 0 1em 1em",
          // }}
        />
        <button
          className={`absolute right-3 top-2 ${
            type !== "password" ? "hidden" : ""
          }`}
          onClick={() => {
            setHidePassword((oldval) => !oldval);
          }}
          type="button"
        >
          <EyeSVG className={"w-5 h-5 "} />
        </button>
      </div>
      {hasError && errorMessage !== "" ? (
        <span className="text-red-500 text-sm font-normal block mt-4">{errorMessage}</span>
      ) : (
        <span className="hidden"></span>
      )}
    </label>
  );
};

export default TextInput;
