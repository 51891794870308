import React, { useEffect, useCallback, useState } from "react";
import DatePicker from "react-multi-date-picker";
import CenteredModal from "../CenteredModal";
import DownArrow from "../svg/DownArrow";
import TrashSVG from "../svg/TrashSVG";

const RestrictedDays = ({ currentUser = {}, ...props }) => {
  const [saving, setSaving] = useState("Save");
  const [showEditModal, setShowEditModal] = useState(false);
  const [restrictedDays, setRestrictedDays] = useState([]);
  const [pastDaysToggle, setPastDaysToggle] = useState(false);
  const [pastDatesToggle, setPastDatesToggle] = useState(false);
  const [rotate, setRotate] = useState("");
  const [datesToRestrict, setDatesToRestrict] = useState([]);
  const [removeRestrictedDays, setRemoveRestrictedDays] = useState([]);

  const getRestrictedDays = useCallback(async () => {
    if (currentUser?.token) {
      try {
        const data = await fetch(
          `${process.env.REACT_APP_API_URL}school/restricted-day`,
          {
            headers: {
              Authorization: "Bearer " + currentUser?.token,
            },
          }
        );
        const res = await data.json();
        if (!res.error) {
          let daysArr = [];
          if (typeof res === "object") {
            for (let day in res["Restricted Days"]) {
              var y = res["Restricted Days"].at(day)["Day"].substr(0, 4),
                m = res["Restricted Days"].at(day)["Day"].substr(5, 2),
                d = res["Restricted Days"].at(day)["Day"].substr(8, 2);
              let date = new Date(y, m, d);
              date.setMonth(date.getMonth() - 1);
              daysArr.push(date);
              daysArr.sort((a, b) => {
                if (a < b) {
                  return -1;
                } else return 1;
              });
            }
            setRestrictedDays(daysArr);
          }
        }
      } catch (error) {
        console.error("error getting restricted days: ", error);
      }
    }
  }, [currentUser?.token]);

  useEffect(() => {
    getRestrictedDays();
  }, [getRestrictedDays]);

  const deleteRestrictedDay = async (date) => {
    date = date.toISOString().split("T")[0];
    try {
      const data = await fetch(
        `${process.env.REACT_APP_API_URL}school/restricted-day/${date}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + currentUser?.token,
          },
        }
      );
      const res = await data.json();
      if (!res.error) {
        console.log("successfully deleted restricted day");
        getRestrictedDays();
      }
    } catch (error) {
      console.error("error getting signup times: ", error);
    }
  };

  const removeDay = (index) => {
    if (saving !== "Save") {
      setSaving("Save");
    }
    setRestrictedDays((prev) => {
      const temp = [...prev];
      temp.splice(index, 1);
      return temp;
    });
    setRemoveRestrictedDays((prev) => {
      const temp = [...prev];
      temp.push(restrictedDays.at(index));
      return temp;
    });
  };

  const submitDays = async () => {
    if (datesToRestrict.length < 1 && removeRestrictedDays <= 1) {
      setShowEditModal(false);
      setSaving("Saved!");
      setRemoveRestrictedDays([]);
      setDatesToRestrict([]);
      return;
    }
    if (removeRestrictedDays.length >= 1) {
      removeRestrictedDays.map((date) => {
        let tempDate = date.toISOString().split("T")[0];
        datesToRestrict.map((day) => {
          let tempDay = day.format("YYYY-MM-DD");
          if (tempDate === tempDay) {
            setRemoveRestrictedDays((prev) => {
              const temp = [...prev];
              temp.splice(temp.indexOf(date), 1);
              return temp;
            });
          }
        });
      });
      let dates = removeRestrictedDays.map(
        (day) => day.toISOString().split("T")[0]
      );
      try {
        const data = await fetch(
          `${process.env.REACT_APP_API_URL}school/restricted-day/delete`,
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + currentUser?.token,
            },
            body: JSON.stringify(dates),
          }
        );
        const res = await data.json();
        if (!res.error) {
          console.log("successfully deleted restricted days");
          setShowEditModal(false);
          setSaving("Saved!");
          setRemoveRestrictedDays([]);
        }
      } catch (error) {
        console.error("error deleting days: ", error);
      }
    }
    if (datesToRestrict.length >= 1) {
      try {
        let datesToSend = [];
        datesToRestrict.map((date) => {
          date = date.format("YYYY-MM-DD");
          datesToSend.push(date);
        });
        setSaving("Saving...");
        const data = await fetch(
          `${process.env.REACT_APP_API_URL}school/add-restricted-days`,
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + currentUser?.token,
            },
            body: JSON.stringify(datesToSend),
          }
        );
        const res = await data.json();
        if (!res.error) {
          console.log("successfully added restricted day");
          setShowEditModal(false);
          setSaving("Saved!");
          setRestrictedDays((prev) => {
            const temp = [...prev];
            datesToRestrict.map((day) => {
              const date = day.toDate();
              temp.push(date);
            });
            temp.sort((a, b) => {
              if (a < b) {
                return -1;
              } else return 1;
            });
            return temp;
          });
        }
      } catch (error) {
        console.error("error adding restricted dates: ", error);
      }
    }
    setDatesToRestrict([]);
  };

  function CustomInput({ openCalendar, value, handleValueChange }) {
    return (
      <input
        name="date"
        placeholder="mm/dd/yyyy"
        onFocus={openCalendar}
        required
        readOnly
        value={datesToRestrict}
        className=" border-gray-300 border rounded-md px-3 py-2 shadow-sm w-full"
      />
    );
  }

  function DateMapper(date) {
    let res;
    restrictedDays.forEach((day) => {
      if (date.format("YYYY-MM-DD") === day.toISOString().split("T")[0]) {
        res = {
          disabled: true,
          style: { color: "#ccc" },
        };
      }
    });
    return res;
  }

  return (
    <div
      className="flex flex-col gap-2 p-2 my-4 border border-gray-300 rounded-md w-full shadow-md"
      {...props}
    >
      <p className="font-semibold text-gray-700">
        Block off days where interventions cannot be scheduled:
      </p>
      <span className="text-base self-start text-gray-800">
        You may select specific dates to resctrict interventions from being
        scheduled.
      </span>
      <span className="text-sm text-gray-400 block self-start">
        (Interventions already scheduled will remain, but new interventions will
        not be able to be created on that date.)
      </span>
      {saving === "Saved!" && (
        <p className="text-green-400">Days saved successfully!</p>
      )}
      {saving === "Saving..." && (
        <p className="text-blue-400">Saving Days...</p>
      )}
      <div
        className="flex flex-col items-start  mt-4"
        style={{ height: "450px", minWidth: "400px" }}
      >
        <div className="flex justify-between w-full flex-row-reverse gap-8">
          <div>
            <span className="font-semibold text-gray-800">
              Add Restricted Days
            </span>
            <label
              htmlFor="date"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Select One or Multiple Days
            </label>
            <button
              disabled={saving === "Save" ? false : true}
              type="button"
              className={`rounded-md shadow-md text-white px-6 py-2 mr-4 disabled:bg-gray-300 ${
                saving === "Save"
                  ? "bg-blueprimary-500 hover:bg-blueprimary-600"
                  : ""
              } `}
              onClick={() => {
                setDatesToRestrict([]);
                setRemoveRestrictedDays([]);
                submitDays();
              }}
            >
              {saving}
            </button>
            <DatePicker
              multiple
              format="MM/DD/YYYY"
              value={datesToRestrict}
              name="date"
              render={<CustomInput />}
              className="border-gray-300 border rounded-md px-3 py-2 shadow-sm w-full form-control"
              style={{
                borderWidth: "1px",
                borderColor: "rgb(209 213 219 / 1)",
              }}
              onOpen={() => {
                setDatesToRestrict([]);
              }}
              onChange={(e) => {
                setDatesToRestrict(e);
                setSaving("Save");
              }}
              calendarPosition={"bottom-right"}
              offsetY={10}
              mapDays={({ date }) => {
                return DateMapper(date);
              }}
            />
          </div>
          <div className="flex flex-col">
            {restrictedDays.length === 0 && (
              <>No restricted days have been set.</>
            )}
            {restrictedDays.length > 0 && (
              <>
                <p className="font-semibold text-gray-800">
                  Current Restricted Days:
                </p>
                <ul>
                  {restrictedDays.map((day) => {
                    if (pastDatesToggle === false && day < new Date())
                      setPastDatesToggle(true);
                    return day >= new Date() ? (
                      <li
                        className="w-full flex flex-row justify-between mt-1"
                        key={day}
                      >
                        {day.toDateString()}
                        <TrashSVG
                          className="text-red-700 cursor-pointer"
                          onClick={() => {
                            removeDay(restrictedDays.indexOf(day));
                          }}
                        />
                      </li>
                    ) : null;
                  })}
                </ul>
                {pastDatesToggle && (
                  <div
                    className="flex flex-row justify-between mt-2"
                    style={{ width: "17rem" }}
                  >
                    <div className="font-semibold text-gray-800">
                      {pastDaysToggle ? "Hide" : "Show"} Past Restricted Days:
                    </div>
                    <DownArrow
                      className={`${rotate} w-3`}
                      onClick={() => {
                        setRotate(rotate == "no-flip" ? "flip" : "no-flip");
                        setPastDaysToggle(!pastDaysToggle);
                      }}
                    />
                  </div>
                )}
                {pastDaysToggle && (
                  <>
                    <ul>
                      {restrictedDays.map((day) => {
                        return day < new Date() ? (
                          <li
                            className="w-full flex flex-row justify-between mt-1"
                            key={day}
                          >
                            {day.toDateString()}
                            <TrashSVG
                              className="text-red-700 cursor-pointer"
                              onClick={() => {
                                removeDay(restrictedDays.indexOf(day));
                              }}
                            />
                          </li>
                        ) : null;
                      })}
                    </ul>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestrictedDays;
