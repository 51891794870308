import React, { useState, useContext, useCallback, useEffect } from "react";
import Navbar from "../components/layouts/Navbar";
import { StateContext } from "../contexts/State";
import Hamburger from "../components/svg/Hamburger";
import SettingsAllStudents from "../components/settings/SettingsAllStudents";
import ExcludedStudents from "../components/settings/ExcludedStudents";
import { BarLoader } from "react-spinners";

const Settings = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [students, setStudents] = useState([]);
  const [excludedStudents, setExcludedStudents] = useState([]);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changesMade, setChangesMade] = useState(false);

  const { state } = useContext(StateContext);

  useEffect(() => {
    console.log(excludedStudents);
  }, [excludedStudents]);

  const getStudents = useCallback(async () => {
    setLoading(true);
    try {
      if (document.getElementById("attendees-searchbar")) {
        document.getElementById("attendees-searchbar").value = "";
      }
      const data = await fetch(`${process.env.REACT_APP_API_URL}all_users`, {
        headers: {
          Authorization: "Bearer " + state.currentUser.token,
        },
      });
      let res = await data.json();
      res = res.filter((user) => user.role === 3);
      setStudents(res);
      setLoading(false);
      if (document.getElementById("attendees-searchbar")) {
        document.getElementById("attendees-searchbar").value = "";
      }
    } catch (error) {
      console.error("error getting students: ", error);
      setLoading(false);
    }
  }, [state.currentUser.token]);

  const getExclusionList = useCallback(async () => {
    setLoading(true);
    try {
      if (document.getElementById("attendees-searchbar")) {
        document.getElementById("attendees-searchbar").value = "";
      }
      const data = await fetch(
        `${process.env.REACT_APP_API_URL}users/exclusion-list`,
        {
          headers: {
            Authorization: "Bearer " + state.currentUser.token,
          },
        }
      );
      let res = await data.json();
      setExcludedStudents(res);
      setLoading(false);
      if (document.getElementById("attendees-searchbar")) {
        document.getElementById("attendees-searchbar").value = "";
      }
    } catch (error) {
      console.error("error getting excluded students: ", error);
      setLoading(false);
    }
  }, [state.currentUser.token]);

  const updateExclusionList = async () => {
    setSaving(true);
    const studentIDs =
      excludedStudents.length > 0
        ? excludedStudents.map((student) => {
            return student.id.toString();
          })
        : [];
    console.log(studentIDs);
    try {
      const data = await fetch(
        `${process.env.REACT_APP_API_URL}users/set-exclusion-list`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + state.currentUser.token,
          },
          body: JSON.stringify(studentIDs),
        }
      );
      let res = await data.json();
      console.log(res);

      setSaving(false);
      setChangesMade(false);
    } catch (error) {
      console.error("error getting excluded students: ", error);
      setSaving(false);
    }
  };

  useEffect(() => {
    if (
      state.currentUser?.user?.role === 1 ||
      state.currentUser?.user?.role === 2 ||
      state.currentUser?.user?.role === 4
    ) {
      getStudents();
      getExclusionList();
    }

    return () => {};
  }, [getStudents, getExclusionList, state.currentUser?.user?.role]);
  return (
    <section>
      <Navbar open={showNavbar} />
      <div
        className="dashboard-content h-screen"
        onClick={() => {
          if (showNavbar) setShowNavbar(false);
        }}
      >
        <Hamburger
          className="hamburger-icon px-4 py-4 h-10 w-10"
          onClick={() => {
            setShowNavbar(true);
          }}
        />
        <h1 className="text-2xl">Settings</h1>
        <p className="w-2/4 mt-4">
          Add and remove students from your "exclusion list". Any students in
          this list <b>will not be able to see your interventions</b> on their
          calendar.
        </p>
        {loading ? (
          <>
            <div className="loader flex flex-col items-center gap-4">
              <h2 className="mb-4 text-blueprimary-600 text-3xl">Loading...</h2>
              <BarLoader color="#4383cb" />
            </div>
          </>
        ) : (
          <div className="flex flex-col gap-2 mt-10">
            <button
              className="self-start rounded-md shadow-md text-white px-4 py-2 disabled:bg-gray-300 bg-blueprimary-500 hover:bg-blueprimary-600"
              disabled={saving || !changesMade}
              type="button"
              onClick={() => {
                updateExclusionList();
              }}
            >
              {saving ? "Saving..." : "Save"}
            </button>
            <div className="flex  gap-4 flex-wrap">
              <SettingsAllStudents
                students={students}
                excludedStudents={excludedStudents}
                setExcludedStudents={setExcludedStudents}
                setChangesMade={setChangesMade}
              />
              <ExcludedStudents
                students={excludedStudents}
                setExcludedStudents={setExcludedStudents}
                setChangesMade={setChangesMade}
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};
export default Settings;
