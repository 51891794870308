import React from "react";

// This component uses a .module.css file, be sure to create one along with this. One is provided below.
// CREATE A DIV WITH ID OF "modal-root" IF YOU WANT A REACT PORTAL
const CenteredModal = ({
  lightBg = false,
  onShowModal = () => {},
  className = "",
  smallModal = false,
  // the "callback" prop is specifically meant for preventing a side-effect of allowing new
  //  interventions to be created by clicking on a date cell. Reference EventWrapper.jsx to see this in action.
  callback = () => {},
  children,
}) => {
  return (
    <div onClick={callback}>
      <div
        className={`centered-backdrop ${lightBg ? "light-bg" : "dark-bg"} `}
        onClick={onShowModal}
      ></div>
      <div
        className={`centered-modal ${className} ${
          smallModal ? "small-modal" : "large-modal"
        } scrollbar`}
      >
        {children}
      </div>
    </div>
  );
};
export default CenteredModal;
