import React from "react";

const Button = ({
  className = "",
  onClick = async () => {},
  successCallback = () => {},
  errorCallback = () => {},
  disabled = false,
  variant = "default",
  children,
  ...props
}) => {
  return (
    <button
      {...props}
      disabled={disabled}
      onClick={
        disabled
          ? () => {}
          : async () => {
              try {
                const data = await onClick();
                await successCallback(data);
              } catch (err) {
                console.log("there was a problem with the button: ", err);
                await errorCallback(err);
              }
            }
      }
      className={`${className} rounded-3xl  px-4 py-2 font-light text-sm disabled:bg-tssGray-600 ${
        variant === "default" &&
        "bg-tssBlue-500 text-white hover:bg-blueprimary-600"
      } ${variant === "secondary" && "bg-tssGray-550 text-tssGray-600"}`}
    >
      {children}
    </button>
  );
};
export default Button;
