import { useState, useContext, useEffect, useCallback, useRef } from "react";
import { StateContext } from "../../contexts/State";
import EditSVG from ".././svg/EditSVG";
import TrashSVG from ".././svg/TrashSVG";
import { BarLoader } from "react-spinners";
import moment from "moment";
import RestrictedTimesNotice from "./RestrictedTimesNotice";
import LockSVG from "../svg/LockSVG";
import EventAttendeesList from "./EventAttendeesList";
import CopySVG from "../svg/CopySVG";

/**
 * Shows the details of an intervention on the overflow menu
 */
const EventDetails = ({
  event,
  setCurrMonthInterventions,
  showMenu,
  setToggleModal,
  setConfirmDelete,
  allowTeacherEdit = false,
  allowAdminDelete = false,
  setIntervention = () => {},
  intervention = {},
  restrictedTimes = [],
  dayOfTheWeek = "",
  restrictTeacherAccess = false,
  setIsCopiedIntervention = () => {},
  isCopiedInterventionWrapper = false,
  setIsCopiedInterventionWrapper,
  setCopiedInterventionDetails,
  setShowInterventionModal,
}) => {
  const { state, dispatch } = useContext(StateContext);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [filteredAttendees, setFilteredAttendees] = useState([]);
  const searchRef = useRef(null);
  // const [intervention, setIntervention] = useState({});

  const getIntervention = useCallback(
    async (id) => {
      if (state.currentUser.token) {
        try {
          setLoading(true);
          if (error) {
            setError(false);
          }
          const res = await fetch(
            `${process.env.REACT_APP_API_URL}v2/intervention/${id}`,
            {
              headers: {
                Authorization: "Bearer " + state.currentUser.token,
              },
            }
          );
          const data = await res.json();
          setIntervention(data);
          setLoading(false);
          console.log("loaded");
        } catch (error) {
          console.error(
            "There was an error getting the intervention details: ",
            error
          );
          setError(
            "There was an error getting the intervention details. Please refresh the page and try again. \n"
          );
          setLoading(false);
        }
      }
    },
    [state.currentUser.token]
  );

  async function attendMeeting(eventId) {
    try {
      const data = await fetch(
        `${process.env.REACT_APP_API_URL}intervention/attend/${eventId}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + state.currentUser.token,
          },
          body: JSON.stringify({
            date: event.date,
          }),
        }
      );
      const res = await data.json();
      if (res.result) {
        setShowModal(false);
        window.location.reload();
      } else if (res.error) {
        setError(res.error);
      }
    } catch (error) {
      console.error(error);
      setError(`There was an error attending this intervention: \n ${error}`);
    }
  }

  async function cancelAttendance(eventId) {
    try {
      const data = await fetch(
        `${process.env.REACT_APP_API_URL}intervention/cancel/${eventId}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + state.currentUser.token,
          },
        }
      );
      const res = await data.json();
      if (res.result) {
        setShowModal(false);
        window.location.reload();
      } else if (res.error) {
        setError(res.error);
      }
    } catch (error) {
      console.error("Error canceling attendance: ", error);
      setError(error);
    }
  }

  useEffect(() => {
    getIntervention(event.id);
  }, [event.id, getIntervention]);

  useEffect(() => {
    if (intervention?.attendees?.length > 0) {
      setFilteredAttendees(intervention.attendees);
    }
  }, [intervention.attendees]);

  function isWithinRestrictedTimes() {
    const temp = new Date().toLocaleString().split(",")[0];
    const isBetweenTimes = moment(new Date()).isBetween(
      new Date(`${temp}, ${restrictedTimes[0]}`),
      new Date(`${temp}, ${restrictedTimes[1]}`)
    );
    return isBetweenTimes;
  }

  const isRestrictedTime =
    isWithinRestrictedTimes(restrictedTimes) &&
    restrictTeacherAccess &&
    (state.currentUser?.user?.role === 2 ||
      state.currentUser?.user?.role === 4);

  function isTeacher() {
    if (
      state.currentUser?.user?.role === 2 ||
      state.currentUser?.user?.role === 4
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className="px-2 w-full flex flex-col gap-4 my-2">
      <div className="flex justify-between w-full ">
        <h2 className="text-3xl font-normal">
          {event?.owner_name} - {event?.title}
        </h2>

        <div className=" flex gap-8 text-gray-600">
          {/* If this intervention is owned by the user
                OR they are a teacher where teacherEditing = true
                AND the user's school does not restrict teacher activity during restricted time
                AND it is not currently within restricted times,
                OR they are an admin
            */}
          {(state.currentUser?.user?.role === 1 ||
            (event.owner === state.currentUser?.user?.id &&
              !isRestrictedTime) ||
            (isTeacher() && allowTeacherEdit && !isRestrictedTime)) && (
            <EditSVG
              className={loading || error ? "text-gray-300" : "cursor-pointer"}
              onClick={() => {
                if (!loading) {
                  setIsCopiedIntervention(false);
                  setToggleModal("edit");
                }
              }}
            />
          )}
          {(state.currentUser?.user?.role === 1 ||
            (event.owner === state.currentUser?.user?.id &&
              !isRestrictedTime) ||
            (isTeacher() && allowTeacherEdit && !isRestrictedTime)) && (
            <CopySVG
              className={loading || error ? "text-gray-300" : "cursor-pointer"}
              onClick={() => {
                if (!loading) {
                  setCopiedInterventionDetails({
                    ...intervention,
                    copied: true,
                  });
                  setIsCopiedInterventionWrapper(true);
                  setIsCopiedIntervention(true);
                  setShowInterventionModal(true);
                  showMenu((prev) => {
                    return { ...prev, showMore: false };
                  });
                }
              }}
            />
          )}
          {((event.owner === state.currentUser?.user?.id &&
            !isRestrictedTime) ||
            (allowAdminDelete && state.currentUser?.user?.role === 1)) && (
            <TrashSVG
              className={loading || error ? "text-gray-300" : "cursor-pointer"}
              // onClick={() => deleteIntervention(event.id)}
              onClick={() => {
                if (!loading) {
                  setConfirmDelete(true);
                }
              }}
            />
          )}
        </div>
      </div>

      {error && <span className="text-red-500 block">{error}</span>}
      {loading ? (
        <div className="m-auto">
          <p className="mb-1 text-blueprimary-600 text-2xl">Loading...</p>
          <BarLoader color="#4383cb" />
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          {state.currentUser?.user?.role !== 3 && intervention?.private && (
            <div className="flex items-center gap-2">
              <p className=" text-base font-semibold text-gray-500">
                Private Intervention
              </p>
              <LockSVG className="text-gray-500 w-6" />
            </div>
          )}
          <p className="text-lg">
            <span className="text-blueprimary-600">Room:</span>{" "}
            {intervention?.room}
          </p>
          {state.currentUser?.user?.role === 3 ? (
            <p className="text-lg">
              <span className="text-blueprimary-600">Seats Remaining: </span>
              {intervention?.capacity_remaining}/{intervention?.capacity}
            </p>
          ) : (
            <p className="text-lg">
              <span className="text-blueprimary-600">
                Intervention Capacity:{" "}
              </span>
              {intervention?.capacity}
            </p>
          )}

          {state.currentUser?.user?.role !== 3 &&
            intervention?.attendees?.length > 0 && (
              <EventAttendeesList
                isOverflowMenu={true}
                currentUser={state.currentUser}
                event={event}
                teacherEditing={allowTeacherEdit}
                intervention={intervention}
                setFilteredAttendees={setFilteredAttendees}
                filteredAttendees={filteredAttendees}
              />
            )}
        </div>
      )}
      {state.currentUser?.user?.role === 3 &&
        (!isWithinRestrictedTimes() ? (
          <div className="flex gap-2">
            {event.color === "blue" && (
              <button
                className="bg-blueprimary-500 text-white px-2 py-1"
                onClick={() => attendMeeting(event.id)}
              >
                Attend
              </button>
            )}
            {event.color === "yellow" && (
              <button
                className="bg-red-500 text-white px-2 py-1"
                onClick={() => cancelAttendance(event.id)}
              >
                Cancel Attendance
              </button>
            )}
          </div>
        ) : (
          <RestrictedTimesNotice
            dayOfTheWeek={dayOfTheWeek}
            restrictedTimes={restrictedTimes}
          />
        ))}
    </div>
  );
};
export default EventDetails;
