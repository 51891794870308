import { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { StateContext } from "./contexts/State";
// import Firebase, { useFirebaseAuthentication } from "./services/firebase";
function App() {
  // const authUser = useFirebaseAuthentication();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(StateContext);
  // useEffect(() => {
  //   // (async () => {
  //   //   if (authUser && state.trainer.id === "") {
  //   //     dispatch(Object.assign(state.firebaseUser, authUser), {
  //   //       type: "STORE_FIREBASE_DATA",
  //   //     });
  //   //     Trainer.getById(authUser.uid)
  //   //       .then((trainer) => {
  //   //         dispatch(Object.assign(state.trainer, trainer), {
  //   //           type: "STORE_TRAINER_DATA",
  //   //         });
  //   //       })
  //   //       .catch((err) => {
  //   //         // Firebase.logoutUser();
  //   //         navigate("/");
  //   //       });
  //   //   }
  //   // })();
  // }, [
  //   authUser,
  //   dispatch,
  //   navigate,
  //   state.firebaseUser,
  //   state.trainer.id,
  //   state.trainer,
  // ]);
  return (
    <div className="App">
      <Outlet />
    </div>
  );
}

export default App;
