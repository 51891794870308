import React, { useState, useContext, useEffect } from "react";
import { StateContext } from "../contexts/State";
import { useDeviceDetect } from "../services/useDeviceDetect";
import Navbar from "../components/layouts/Navbar";
import Hamburger from "../components/svg/Hamburger";
import UsersList from "../components/admin/UsersList";
import ManageInterventionSetting from "../components/admin/ManageInterventionSetting";

import ManageInterventionPermissions from "../components/admin/ManageInterventionPermissions";

const Admin = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const { state } = useContext(StateContext);

  return (
    <section>
      <Navbar open={showNavbar} />
      <div
        className="dashboard-content h-screen"
        onClick={() => {
          if (showNavbar) setShowNavbar(false);
        }}
      >
        <Hamburger
          className="hamburger-icon px-4 py-4 h-10 w-10"
          onClick={() => {
            setShowNavbar(true);
          }}
        />
        <ManageInterventionPermissions currentUser={state?.currentUser} />
        <ManageInterventionSetting currentUser={state?.currentUser} />
        <UsersList />
      </div>
    </section>
  );
};
export default Admin;
