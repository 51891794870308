import React, { useState } from "react";
import PersonSVG from "../svg/PersonSVG";
import DownArrow from "../svg/DownArrow";
import MultiInterventionSVG from "../svg/MultiInterventionSVG";

const CalendarLegend = ({ role = 3 }) => {
  const [showLegend, setShowLegend] = useState(false);

  return (
    <div className="relative">
      <button
        className={`gap-2 flex items-center justify-between border-[1.5px]  border-tssGray-500 rounded-3xl py-2 px-4`}
        onClick={() => {
          setShowLegend(!showLegend);
        }}
      >
        <span className="text-tssGray-600 font-light text-sm">Legend</span>
        <DownArrow className="text-tssBlack-500 w-2.5" />
      </button>
      {showLegend && (
        <div className="text-tssGray-600 right-0 mt-2 flex items-center gap-4 flex-col absolute bg-white border-[1.5px] rounded-2xl px-6 py-2 z-20">
          <div className="flex  gap-4 items-center">
            <div className="bg-blueprimary-500 w-2 h-2 rounded-full shadow-sm flex-shrink-0"></div>
            <span className="text-sm">Open Intervention</span>
          </div>
          {(role === 1 || role === 2 || role === 4) && (
            <div className="flex  gap-4 items-center">
              <div className="bg-redprimary-500 w-2 h-2 rounded-full shadow-sm flex-shrink-0"></div>
              <span className="text-sm">Closed Intervention</span>
            </div>
          )}
          <div className="flex  gap-4 items-center">
            <div className="bg-gray-500 w-2 h-2 rounded-full shadow-sm flex-shrink-0"></div>
            <span className="text-sm">Assigned Intervention</span>
          </div>
          <div className="flex  gap-4 items-center">
            <div className="bg-yellow-400 w-2 h-2 rounded-full shadow-sm flex-shrink-0"></div>
            <span className="text-sm">Selected Intervention</span>
          </div>
          {role !== 3 && (
            <div className="flex gap-4 items-center -mx-1">
              <PersonSVG className="w-7" />
              <span className="text-sm">Self-assigned Student</span>
            </div>
          )}
          {role === 3 ? (
            <div className="flex gap-4 items-center -mx-1">
              <MultiInterventionSVG className="w-7" />
              <span className="text-sm">Multiple Intervention Signups</span>
            </div>
          ) : (
            <div className="flex gap-4 items-center -mx-1">
              <MultiInterventionSVG className="w-7 block" />
              <span className="text-sm">Multiple Interventions Enabled</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default CalendarLegend;
