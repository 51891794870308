import React from "react";

const DownArrow = ({ className, onClick }) => {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8 6"
      fill="currentColor"
      className={`${className}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.28581C8 1.43211 7.944 1.57836 7.83255 1.68981L4.40405 5.11832C4.18065 5.34174 3.8195 5.34174 3.5961 5.11832L0.167569 1.68981C-0.0558563 1.46636 -0.0558563 1.10526 0.167569 0.88181C0.390994 0.65841 0.752129 0.65841 0.975554 0.88181L4.00005 3.90636L7.0246 0.88181C7.248 0.65841 7.60915 0.65841 7.83255 0.88181C7.944 0.99326 8 1.13951 8 1.28581Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default DownArrow;
