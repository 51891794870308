import React, { useState, useEffect } from "react";
import SmallTextInput from "../SmallTextInput";

const EditUser = ({
  token = "",
  selectedUser = {},
  setShowModal = {},
  setUsers = {},
  searchRef = null,
}) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showError, setShowError] = useState(null);
  const [role, setRole] = useState(selectedUser.role);
  const [grade, setGrade] = useState(6);
  const [firstName, setFirstName] = useState(selectedUser.first_name);
  const [lastName, setLastName] = useState(selectedUser.last_name);
  const [email, setEmail] = useState(selectedUser.email);

  async function submitEditedUser() {
    try {
      const newGrade = role != 3 ? -1 : grade;
      const res = await fetch(
        process.env.REACT_APP_API_URL + "users/edit-user/" + selectedUser.id,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            role: role,
            grade_level: newGrade,
            first_name: firstName,
            last_name: lastName,
            email: email,
          }),
        }
      );
      const data = await res.json();
      if (!res.ok) throw new Error(data.error);
      setShowModal(null);
      setUsers((prev) => {
        const index = prev.findIndex((i) => i.id === selectedUser.id);
        prev[index].role = role;
        prev[index].grade_level = grade;
        prev[index].email = email;
        prev[index].first_name = firstName;
        prev[index].last_name = lastName;
        return [...prev];
      });
      searchRef.current.value = "";
    } catch (e) {
      setShowError(e.message);
    }
  }

  async function submitNewPassword() {
    if (password === confirmPassword && password.length > 0) {
      try {
        const res = await fetch(
          process.env.REACT_APP_API_URL +
            "users/change_password/" +
            selectedUser.id,
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify({ new_password: password }),
          }
        );
        const data = await res.json();
        if (!res.ok) throw new Error(data.error);
        setShowModal(null);
      } catch (e) {
        setShowError(e.message);
      }
      searchRef.current.value = "";
    } else {
      console.log("no match");
      setShowError("Passwords must match.");
    }
  }

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitEditedUser();
        }}
      >
        <h4>Update Role for: {selectedUser.email}</h4>
        <div className="flex flex-col gap-2 items-stretch mt-4 text-sm">
          <div className="flex flex-col gap-2 items-stretch mt-4 text-sm">
            <SmallTextInput
              className="w-full"
              id="new-first-name"
              label="First Name"
              type="text"
              defaultValue={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
                setShowError(null);
              }}
              onBlur={() => {}}
              hasError={false}
              errorMessage={showError}
            />
            <SmallTextInput
              className="w-full"
              id="new-last-name"
              label="Last Name"
              type="Text"
              defaultValue={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
                setShowError(null);
              }}
              onBlur={() => {}}
              hasError={false}
              errorMessage={showError}
            />
            <SmallTextInput
              className="w-full"
              id="new-email"
              label="Email"
              type="Text"
              defaultValue={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setShowError(null);
              }}
              onBlur={() => {}}
              hasError={false}
              errorMessage={showError}
            />
            {showError && (
              <span className="text-redprimary-500 mx-auto">{showError}</span>
            )}
          </div>
          <label
            htmlFor="select-user-role"
            className="w-full"
            style={{ fontWeight: "700", fontSize: "1rem" }}
          >
            New Role
          </label>
          <select
            name="select-user-role"
            className="text-sm px-2 py-1 border border-gray-400 rounded-md shadow-md"
            defaultValue={role}
            onChange={(e) => {
              setRole(+e.target.value);
              setGrade(+e.target.value === 3 ? 6 : -1);
            }}
          >
            <option value="3">Student</option>
            <option value="2">Teacher</option>
            <option value="1">Admin</option>
            <option value="4">Staff</option>
          </select>
          {role === 3 && (
            <div className="flex flex-col items-start mt-4">
              <label
                htmlFor="select-user-grade"
                className="font-bold text-base"
              >
                Select a grade for this user:
              </label>
              <select
                name="select-user-grade"
                className="text-sm px-2 py-1 border border-gray-400 rounded-md shadow-md"
                onChange={(e) => {
                  setGrade(+e.target.value);
                }}
              >
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
            </div>
          )}
        </div>
        <div className="mt-4 flex items-center justify-between">
          <span></span>
          <button
            className="text-sm bg-blueprimary-500 rounded-md shadow-md px-2 py-1 text-white hover:bg-blueprimary-600"
            // TODO: add edit user functionality
            type="submit"
          >
            Update User
          </button>
        </div>
      </form>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitNewPassword();
        }}
      >
        <h4 className="mt-4">Update Password for: {selectedUser.email}</h4>
        <div className="flex flex-col gap-2 items-stretch mt-4 text-sm">
          <SmallTextInput
            className="w-full"
            id="new-password"
            label="New password"
            type="password"
            placeholder="New password"
            onChange={(e) => {
              setPassword(e.target.value);
              setShowError(null);
            }}
            onBlur={() => {}}
            hasError={false}
            errorMessage={showError}
          />
          <SmallTextInput
            className="w-full"
            id="confirm-password"
            label="Confirm new password"
            type="password"
            placeholder="confirm new password"
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              setShowError(null);
            }}
            onBlur={() => {}}
            hasError={false}
            errorMessage={showError}
          />
          {showError && (
            <span className="text-redprimary-500 mx-auto">{showError}</span>
          )}
        </div>
        <div className="mt-4 flex items-center justify-between">
          <button
            type="button"
            className="text-sm bg-gray-500 rounded-md shadow-md px-2 py-1 text-white hover:bg-gray-600"
            onClick={() => {
              setShowModal(null);
            }}
          >
            Cancel
          </button>
          <button
            className="text-sm bg-blueprimary-500 rounded-md shadow-md px-2 py-1 text-white hover:bg-blueprimary-600"
            // TODO: add edit user functionality
            type="submit"
          >
            Update User Password
          </button>
        </div>
      </form>
    </>
  );
};
export default EditUser;
