import React, { useEffect, useCallback, useState } from "react";
import Button from "../Button";
import DownArrow from "../svg/DownArrow";
import MultipleInterventionDates from "./MultipleInterventionDates";

const ManageInterventionSetting = ({ currentUser = {}, ...props }) => {
  const [allowMultipleInterventions, setAllowMultipleInterventions] =
    useState(false);
  const [allowMultipleSignups, setAllowMultipleSignups] = useState(false);
  const [assignChangeMade, setAssignChangeMade] = useState(false);
  const [signupChangeMade, setSignupChangeMade] = useState(false);
  const [openDropdown, setOpendropdown] = useState(false);
  const [multiInterventionDates, setMultiInterventionDates] = useState([]);
  const [currMonth, setCurrMonth] = useState(new Date().getMonth() + 1)

  const getTeacherEditing = useCallback(async () => {
    if (currentUser?.token) {
      try {
        const data = await fetch(
          `${process.env.REACT_APP_API_URL}school/school-settings?month=${currMonth}&include_next_two_months=true`,
          {
            headers: {
              Authorization: "Bearer " + currentUser?.token,
            },
          }
        );
        const res = await data.json();
        if (!res.error) {
          setAllowMultipleInterventions(res.allow_multiple_interventions);
          setAllowMultipleSignups(res.allow_multiple_intervention_signups);
          setMultiInterventionDates(res.multi_intervention_dates);
        }
      } catch (error) {
        console.error("error getting signup times: ", error);
      }
    }
  }, [currentUser?.token, currMonth]);

  useEffect(() => {
    getTeacherEditing();
  }, [getTeacherEditing]);

  async function saveMultipleInterventionsSetting(value) {
    if (!currentUser?.token) return;
    try {
      const data = await fetch(
        `${process.env.REACT_APP_API_URL}school/allow-multiple-interventions/${currentUser?.user?.school}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + currentUser?.token,
          },
          body: JSON.stringify({
            allow_multiple_interventions: value,
          }),
        }
      );
      const res = await data.json();
      setAssignChangeMade(false);
    } catch (error) {
      console.error("error getting signup times: ", error);
    }
  }

  async function saveMultipleSignupSetting(value) {
    if (!currentUser?.token) return;
    try {
      const data = await fetch(
        `${process.env.REACT_APP_API_URL}school/allow-multiple-intervention-signups/${currentUser?.user?.school}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + currentUser?.token,
          },
          body: JSON.stringify({
            allow_multiple_intervention_signups: value,
          }),
        }
      );
      const res = await data.json();
      setSignupChangeMade(false);
    } catch (error) {
      console.error("error getting signup times: ", error);
    }
  }

  async function saveSettings() {
    if (assignChangeMade) {
      console.log("saving assign to multiple int...");
      await saveMultipleInterventionsSetting(allowMultipleInterventions);
    }
    if (signupChangeMade) {
      console.log("saving signup to multiple int...");
      await saveMultipleSignupSetting(allowMultipleSignups);
    }
    console.log("saving complete");
  }

  return (
    <div className="flex gap-4 items-start justify-start p-2 my-4 border-t border-gray-300 w-full">
      <button
        className="w-4 h-4 mt-3"
        onClick={() => {
          setOpendropdown(!openDropdown);
        }}
      >
        <DownArrow className={`${openDropdown ? "" : "rotate-[270deg]"}`} />
      </button>
      <div {...props}>
        <h3 className="text-gray-800 text-3xl font-medium">
          Multiple Intervention Settings
        </h3>
        <p className="text-md font-light mb-4 text-gray-500">
          As an admin, you can select specific days where students can be
          assigned to <i>multiple</i> interventions in one day, as well as
          allowing students to sign themselves up for <i>multiple</i>{" "}
          interventions in one day.
        </p>
        <div
          className={`flex flex-col items-start gap-2 ${
            openDropdown ? "visible" : "hidden"
          }`}
        >
          <label className="font-normal text-base text-tssBlack-500">
            <input
              id="admin-allow"
              className="w-5 h-5 mr-2 align-text-bottom"
              type="checkbox"
              checked={allowMultipleInterventions}
              onChange={(e) => {
                setAllowMultipleInterventions(e.target.checked);
                setAssignChangeMade(!assignChangeMade);
              }}
            />
            Allow students to be assigned to multiple interventions in a day?
          </label>
          <label className="font-normal text-base text-tssBlack-500">
            <input
              id="admin-allow"
              className="w-5 h-5 mr-2 align-text-bottom"
              type="checkbox"
              checked={allowMultipleSignups}
              onChange={(e) => {
                setAllowMultipleSignups(e.target.checked);
                setSignupChangeMade(!signupChangeMade);
              }}
            />
            Allow students to sign up for multiple interventions in a day?
          </label>
          <button
            disabled={!signupChangeMade && !assignChangeMade}
            type="button"
            className="rounded-md shadow-sm w-20 text-white px-2 py-1 disabled:bg-gray-300 bg-blueprimary-500 hover:bg-blueprimary-600"
            onClick={() => {
              saveSettings();
            }}
          >
            Save
          </button>
          <MultipleInterventionDates currentUser={currentUser} multiInterventionDates={multiInterventionDates} setMultiInterventionDates={setMultiInterventionDates} />
        </div>
      </div>
    </div>
  );
};
export default ManageInterventionSetting;
