import React, { useEffect, useCallback, useState } from "react";
import Button from "../Button";

const ManageAdminDeleting = ({ currentUser = {}, ...props }) => {
  const [adminDeleting, setAdminDeleting] = useState(false);
  let [changeMade, setchangeMade] = useState(false);

  const getTeacherEditing = useCallback(async () => {
    if (currentUser?.token) {
      try {
        const data = await fetch(
          `${process.env.REACT_APP_API_URL}school/school-settings`,
          {
            headers: {
              Authorization: "Bearer " + currentUser?.token,
            },
          }
        );
        const res = await data.json();
        if (!res.error) {
          setAdminDeleting(res.allow_admin_delete);
        }
      } catch (error) {
        console.error("error getting signup times: ", error);
      }
    }
  }, [currentUser?.token]);

  useEffect(() => {
    getTeacherEditing();
  }, [getTeacherEditing]);

  async function saveTeacherEditing(value) {
    if (!currentUser?.token) return;
    try {
      const data = await fetch(
        `${process.env.REACT_APP_API_URL}school/admin-delete/${currentUser?.user?.school}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + currentUser?.token,
          },
          body: JSON.stringify({
            allow_admin_delete: value,
          }),
        }
      );
      const res = await data.json();
      setchangeMade(false);
    } catch (error) {
      console.error("error getting signup times: ", error);
    }
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        saveTeacherEditing(adminDeleting);
      }}
      className="flex flex-col gap-2"
      {...props}
    >
      <label className="font-normal text-base text-tssBlack-500">
        <input
          id="admin-allow"
          name="edit-permission"
          className="w-5 h-5 mr-2 align-text-bottom"
          type="checkbox"
          checked={adminDeleting}
          onChange={(e) => {
            setAdminDeleting(!adminDeleting);
            setchangeMade(true);
          }}
        />
        Allow admins to delete any intervention?
      </label>
      <button
        disabled={!changeMade}
        type="submit"
        className="rounded-md shadow-sm w-20 text-white px-2 py-1 disabled:bg-gray-300 bg-blueprimary-500 hover:bg-blueprimary-600"
      >
        Save
      </button>
    </form>
  );
};
export default ManageAdminDeleting;
