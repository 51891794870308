import { Link, useMatch, useResolvedPath } from "react-router-dom";

const NavbarLink = ({ children, to, className, ...props }) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: false });
  return (
    <Link
      to={to}
      className={`${className} rounded-3xl px-3 py-1 font-light text-sm ${match ? "bg-tssBlue-500 text-white" : "text-tssGray-600 hover:text-tssBlack-500"}`}
      {...props}
    >
      {children}
    </Link>
  );
};

export default NavbarLink;
