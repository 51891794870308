import React from "react";

const ManageTimeInput = ({
  setTimes = () => {},
  timesToday = [],
  day = "",
  setSaving = () => {},
}) => {
  return (
    <div className="flex items-end gap-4 p-2 pb-5 justify-between w-full border-gray-200 border-b">
      <span className="font-semibold text-gray-800">{day}</span>
      <div className="flex gap-2 items-center">
        <input
          type="time"
          name="time-start"
          value={timesToday[0]}
          className="border border-gray-300 rounded-md shadow-sm px-2"
          onChange={(e) => {
            console.log(e.target.value);
            setTimes((prev) => {
              const temp = { ...prev };
              temp[day][0] = e.target.value;
              return temp;
            });
            setSaving("Save");
          }}
        />
        <span className="font-bold text-gray-800">to</span>
        <input
          type="time"
          name="time-end"
          value={timesToday[1]}
          className="border border-gray-300 rounded-md shadow-sm px-2"
          onChange={(e) => {
            console.log(e.target.value);
            setTimes((prev) => {
              const temp = { ...prev };
              temp[day][1] = e.target.value;
              return temp;
            });
            setSaving("Save");
          }}
        />
      </div>
    </div>
  );
};
export default ManageTimeInput;
