import React, { useState, useContext } from "react";
import EventDetails from "./EventDetails";
import { StateContext } from "../../contexts/State";
import EditIntervention from "./EditIntervention";
import ConfirmDeleteEvent from "./ConfirmDeleteEvent";

const OverflowMenuItem = ({
  event,
  setCurrMonthInterventions,
  showMenu,
  allowTeacherEdit = false,
  allowAdminDelete = false,
  restrictedTimes = [],
  dayOfTheWeek = "",
  allowMultipleInterventions,
  restrictTeacherAccess = false,
  isCopiedInterventionWrapper = false,
  setIsCopiedInterventionWrapper,
  setCopiedInterventionDetails,
  setShowInterventionModal
}) => {
  const { state, dispatch } = useContext(StateContext);
  const [toggleModal, setToggleModal] = useState("event");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [intervention, setIntervention] = useState({});
  // isCopiedIntervention is used when a user clicks the "copy" icon
  const [isCopiedIntervention, setIsCopiedIntervention] = useState(false);
  console.log("event: ", event);

  async function deleteSubsequentInterventions() {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}intervention/delete/repeating/${event?.intervention_series_id}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + state.currentUser.token,
          },
          body: JSON.stringify({
            date: event?.date,
          }),
        }
      );
      const data = await res.json();
      await setCurrMonthInterventions((prev) => {
        const temp = { ...prev };
        const tempAllInterventions = temp.all_interventions;
        for (const interventionId of data?.deleted_ints) {
          tempAllInterventions.splice(
            temp.all_interventions.findIndex(
              (intervention) => intervention.id === interventionId
            ),
            1
          );
        }
        if (state.currentUser?.user?.role === 2) {
          const tempMyInterventions = temp.my_interventions;
          for (const interventionId of data?.deleted_ints) {
            tempMyInterventions.splice(
              temp.my_interventions.findIndex(
                (intervention) => intervention.id === interventionId
              ),
              1
            );
          }
          return {
            ...prev,
            my_interventions: [...prev.my_interventions, tempMyInterventions],
            all_interventions: [
              ...prev.all_interventions,
              tempAllInterventions,
            ],
          };
        } else {
          return {
            ...prev,
            all_interventions: [
              ...prev.all_interventions,
              tempAllInterventions,
            ],
          };
        }
      });
      showMenu((prev) => {
        return { ...prev, showMore: false };
      });
    } catch (error) {
      console.error("error deleting all interventions: ", error);
    }
  }

  async function deleteIntervention() {
    try {
      await fetch(
        `${process.env.REACT_APP_API_URL}intervention/delete/${event.id}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + state.currentUser.token,
          },
        }
      );
      setCurrMonthInterventions((prev) => {
        const role = state.currentUser?.user?.role;
        const temp = { ...prev };
        if (role === 2) {
          const tempNewInterventions = [...temp.my_interventions];
          const tempAllInterventions = [...temp.all_interventions];
          console.log(tempNewInterventions);
          tempNewInterventions.splice(
            temp.my_interventions.findIndex(
              (intervention) => intervention.id === event.id
            ),
            1
          );
          tempAllInterventions.splice(
            temp.all_interventions.findIndex(
              (intervention) => intervention.id === event.id
            ),
            1
          );
          return {
            ...temp,
            my_interventions: [...tempNewInterventions],
            all_interventions: [...tempAllInterventions],
          };
        } else {
          const tempNewInterventions = [...temp.all_interventions];
          console.log(tempNewInterventions);
          tempNewInterventions.splice(
            temp.all_interventions.findIndex(
              (intervention) => intervention.id === event.id
            ),
            1
          );
          return { ...temp, all_interventions: [...tempNewInterventions] };
        }
      });
      showMenu((prev) => {
        return { ...prev, showMore: false };
      });
    } catch (error) {
      console.error("Error deleting intervention: ", error);
    }
  }

  if (confirmDelete) {
    return (
      <ConfirmDeleteEvent
        className="self-start"
        currDate={event?.date}
        setShowConfirmDelete={setConfirmDelete}
        setShowModal={showMenu}
        isRepeatingEvent={event?.intervention_series_id ? true : false}
        deleteOne={() => {
          deleteIntervention();
          showMenu((prev) => {
            return { ...prev, showMore: false };
          });
        }}
        setDeleteAll={() => {
          deleteSubsequentInterventions();
        }}
        cancelBtnCallback={() => {
          setConfirmDelete(false);
        }}
      />
    );
  } else if (toggleModal === "event") {
    return (
      <EventDetails
        setIsCopiedIntervention={setIsCopiedIntervention}
        allowTeacherEdit={allowTeacherEdit}
        allowAdminDelete={allowAdminDelete}
        showMenu={showMenu}
        event={event}
        setCurrMonthInterventions={setCurrMonthInterventions}
        setToggleModal={setToggleModal}
        setConfirmDelete={setConfirmDelete}
        intervention={intervention}
        setIntervention={setIntervention}
        restrictedTimes={restrictedTimes}
        dayOfTheWeek={dayOfTheWeek}
        restrictTeacherAccess={restrictTeacherAccess}
        isCopiedInterventionWrapper={isCopiedInterventionWrapper}
        setIsCopiedInterventionWrapper={setIsCopiedInterventionWrapper}
        setCopiedInterventionDetails={setCopiedInterventionDetails}
        setShowInterventionModal={setShowInterventionModal}
      />
    );
  } else if (toggleModal === "edit") {
    return (
      <EditIntervention
        setIsCopiedIntervention={setIsCopiedIntervention}
        className="w-full"
        setShowEditModal={setToggleModal}
        event={intervention}
        hideTitle={true}
        setInterventions={setCurrMonthInterventions}
        isCopiedIntervention={isCopiedIntervention}
        callback={async (res) => {
          await showMenu((prev) => {
            const formattedIntervention = {
              ...res,
              color: "blue",
              start: new Date(`${res.date}T10:00:00`).toUTCString(),
              end: new Date(`${res.date}T10:00:00`).toUTCString(),
              title: res.name,
            };
            console.log(formattedIntervention);
            const temp = [...prev.events];
            temp.splice(
              temp.findIndex((intervention) => intervention.id === event.id),
              1,
              formattedIntervention
            );
            console.log(temp);
            return { ...prev, events: temp };
          });
          setToggleModal("event");
        }}
        allowMultipleInterventions={allowMultipleInterventions}
      />
    );
  }
};
export default OverflowMenuItem;
