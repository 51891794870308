import React, { useEffect, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import { StateContext } from "../contexts/State";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import HomeFooter from "../components/home/HomeFooter";
import Testimonials from "../components/home/Testimonials";
import PreviewImages from "../components/home/PreviewImages";
const Home = () => {
  const { state, dispatch } = useContext(StateContext);
  const navigate = useNavigate();

  function getRememberMeCookie() {
    const value = ("; " + document.cookie)
      .split(`; tss_remember_me=`)
      .pop()
      .split(";")[0];
    return value ? value : "";
  }

  const getCurrentUserIfCookieExists = useCallback(async () => {
    if (getRememberMeCookie() === "keep_logged_in") {
      console.log("getting current user...");
      try {
        const data = await fetch(
          `${process.env.REACT_APP_API_URL}current_user`,
          {
            headers: {
              Authorization: "Bearer " + state.currentUser?.token,
            },
          }
        );
        const res = await data.json();
        const token = { token: state.currentUser?.token };
        dispatch({
          type: "STORE_USER_DATA",
          payload: { ...token, user: res?.user },
        });
        localStorage.setItem(
          "currentUser",
          JSON.stringify({ ...token, user: res?.user })
        );
        if (res.error) {
          console.log(res.error);
          if (
            res.error &&
            (res.error === "JWT Signature Expired" ||
              res.error === "JWT Token Expired")
          ) {
            document.cookie =
              "tss_remember_me=keep_logged_in; max-age=0; SameSite=lax; path=/";
            navigate("/login");
          }
        } else {
          navigate("/dashboard");
        }
      } catch (error) {
        console.error("There was an error getting the current user: ", error);
      }
    }
  }, [dispatch, navigate, state.currentUser?.token]);

  useEffect(() => {
    if (
      getRememberMeCookie() === "keep_logged_in" &&
      state.currentUser?.user?.role === 3
    ) {
      getCurrentUserIfCookieExists();
      navigate("/dashboard");
    }
  }, [getCurrentUserIfCookieExists, navigate, state.currentUser?.user?.role]);

  return (
    <div className="">
      <nav className="mx-12 lg:mx-24 mt-6 mb-36  flex flex-row m-2 space-between items-center">
        <div>
          <img src="tss-nav.png" alt="" className="h-10 lg:h-auto" />
        </div>
        <div className="">
          {/* <div className="flex gap-4 px-4 py-2 bg-tssBlue-200 rounded-3xl text-sm font-light text-tssGray-600">
            <span>Features</span>
            <span>Pricing</span>
            <span>About</span>
          </div> */}
          {getRememberMeCookie() === "keep_logged_in" ? (
            <Button
              type="button"
              onClick={() => {
                console.log("dashboard btn clicked");
                getCurrentUserIfCookieExists();
              }}
            >
              Dashboard
            </Button>
          ) : (
            <Link to="/login">
              <Button
                onClick={() => {
                  console.log("login");
                }}
              >
                Login
              </Button>
            </Link>
          )}
        </div>
      </nav>
      <main className="mx-12 lg:mx-24">
        <section>
          <article className="flex flex-col items-start">
            <h4 className="text-tssBlue-500 font-normal text-base mb-6">
              Optimizing Effectiveness of Tiered Intervention
            </h4>
            <div>
              <h1 className="text-tssBlack-500 font-normal text-5xl leading-[40px]  lg:leading-[60px] max-w-[850px]">
                Tiered System Support is an innovative calendar that gives
                seamless organization and optimal efficiency for you.
              </h1>
            </div>
            <h4 className="mt-2 font-light text-tssGray-600 leading-7 text-lg max-w-[650px]">
              Master your intervention schedule, organize your sessions with
              staff, and achieve more effective intervention with students. Our
              software enables quick and easy scheduling, ensuring that schools
              can provide quality intervention effortlessly and stay on top of
              other PLC commitments with ease.
            </h4>
            <a
              href="#contact-us
            "
              className="mt-8 block"
            >
              <Button>Contact Our Team</Button>
            </a>
            <div className="self-center sm:max-w-xl md:max-w-2xl lg:max-w-full p-4  mt-6 bg-[#7A8DAA14] rounded-2xl">
              <img
                src="./calendar-preview-2.png"
                className="rounded-2xl"
                alt="TSS calendar preview"
              />
            </div>
          </article>

          <PreviewImages className="my-[7.5rem]" />

          {/* <Testimonials className="" /> */}
        </section>
      </main>
      <HomeFooter />
    </div>
  );
};

export default Home;
