import React, { useEffect } from "react";
import DownArrow from "./svg/DownArrow";

const Toolbar = ({ date, setMonthIndex, onNavigate, label, setYearIndex }) => {
  useEffect(() => {
    setMonthIndex(date.getMonth() + 1);
    setYearIndex(date.getFullYear());
  }, [date, setMonthIndex, setYearIndex]);

  const goToBack = () => {
    onNavigate("PREV");
    const newMonth = date.getMonth() + 1;
    const newYear = date.getFullYear();
    setMonthIndex(newMonth);
    setYearIndex(newYear);
  };

  const goToNext = () => {
    onNavigate("NEXT");
    const newMonth = date.getMonth() + 1;
    const newYear = date.getFullYear();
    setMonthIndex(newMonth);
    setYearIndex(newYear);
  };

  return (
    <div className="flex gap-2 items-center justify-between w-full mb-10">
      <button
        className="flex items-center justify-center text-tssBlack-500 bg-[#3E6DB114] w-9 h-9  rounded-full hover:text-[#EBEEF4] hover:bg-tssBlack-500"
        onClick={goToBack}
      >
        <DownArrow className={"w-4 rotate-90 text-center "} />
      </button>
      <h3 className="justify-self-center text-2xl text-tssBlack-500">{label}</h3>
      <button
        className="flex items-center justify-center text-tssBlack-500 bg-[#3E6DB114] w-9 h-9  rounded-full hover:text-[#EBEEF4] hover:bg-tssBlack-500"
        onClick={goToNext}
      >
        <DownArrow className={"w-4 rotate-[270deg] text-center"} />
      </button>
    </div>
  );
};
export default Toolbar;
