import React, { Component } from "react";
// import SearchSVG from "./svg/SearchSVG";
// import TextSVGContainer from "./svg/TextSVGContainer";

/**
 * SearchBar Component
 * Takes in an array and field names through which it should search for
 * search terms typed in
 * @param id Id to be given to search input
 * @param className Additional class names to be applied
 * @param updateArray Function that is passed the newly filtered array
 * @param inputArray Input array through which to search
 * @param searchFields Field names to consider in search
 * @param placeholder Placeholder text to be displayed
 */
class SearchBar extends Component {
  static defaultProps = {
    id: "",
    className: "",
    inputRef: React.createRef(),
    updateArray: (newArray) => {
      /* This should update our display array */
    },
    inputArray: [],
    searchFields: [],
    placeholder: "Search...",
  };
  count = 0;

  // Iterates through each item and verifies whether it meets search criteria
  filterArray = (value) => {
    let newArray = this.props.inputArray.filter((item) => {
      let matches = false;
      this.props.searchFields.forEach((field) => {
        let terms = field.split(".");
        let compareValue = item;
        terms.forEach((term) => {
          compareValue = compareValue[term];
        });
        // console.log(compareValue);
        // console.log(typeof compareValue);
        if (
          typeof compareValue === "string" ||
          compareValue instanceof String
        ) {
          if (compareValue.toLowerCase().includes(value.toLowerCase())) {
            matches = true;
          }
        } else if (Array.isArray(compareValue)) {
          console.log("Handling it like an array");
          compareValue.forEach((comp) => {
            if (comp.toLowerCase().includes(value.toLowerCase())) {
              matches = true;
            }
          });
        }
      });
      return matches;
    });
    this.props.updateArray(newArray);
  };

  // Simple debounce function for performance that ensures that
  // at least 300ms pass between the last keystroke and the filter running
  startFilter = (value) => {
    this.count++;
    let countCopy = this.count;
    setTimeout(() => {
      if (this.count == countCopy) {
        this.filterArray(value);
      }
    }, 300);
  };

  render() {
    return (
      <div className={"search-bar " + this.props.className}>
        <span className="search-icon">
          {/* <TextSVGContainer>
            <SearchSVG />
          </TextSVGContainer> */}
        </span>
        <input
          ref={this.props.inputRef}
          id={this.props.id}
          className={"input"}
          type="text"
          placeholder={this.props.placeholder}
          name={this.props.name}
          onChange={(e) => {
            this.startFilter(this.props.inputRef?.current?.value);
          }}
        ></input>
      </div>
    );
  }
}

export default SearchBar;
