import React from "react";

const ConfirmDeleteEvent = ({
  isRepeatingEvent = false,
  deleteOne = () => {},
  setDeleteAll = () => {},
  cancelBtnCallback = () => {},
  className = "",
  currDate = ""
}) => {
  return (
    <div className={`${className}`}>
      <span>Are you sure you want to delete this intervention?</span>

      {isRepeatingEvent && (
        <span className="w-80 block mt-4 text-sm text-gray-600">
          <b>Note</b>: This is a repeating intervention. You can choose to delete this intervention and {" "}
          <u>every subsequent</u> ({currDate}) occurrence of this intervention, or just this one occurrence.
        </span>
      )}
      <div className="flex items-center gap-20 mt-10 justify-between text-base">
        <button
          className="bg-blueprimary-500 rounded-md shadow-md px-2 py-1 text-white hover:bg-blueprimary-600"
          onClick={cancelBtnCallback}
        >
          Cancel
        </button>
        <div className="flex items-center gap-4">
          {isRepeatingEvent && (
            <button
              className="bg-redprimary-500 rounded-md shadow-md px-2 py-1 text-white hover:bg-redprimary-600"
              onClick={setDeleteAll}
            >
              Delete Subsequent Interventions
            </button>
          )}

          {isRepeatingEvent ? (
            <button
              className="bg-yellow-500 rounded-md shadow-md px-2 py-1 text-white hover:bg-yellow-600"
              onClick={deleteOne}
            >
              Delete One
            </button>
          ) : (
            <button
              className="bg-redprimary-500 rounded-md shadow-md px-2 py-1 text-white hover:bg-redprimary-600"
              onClick={deleteOne}
            >
              Delete
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
export default ConfirmDeleteEvent;
