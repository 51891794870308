import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { StateContext } from "../../contexts/State";
import CenteredModal from "../CenteredModal";
import SearchBar from "../SearchBar";
import { BarLoader } from "react-spinners";
import TrashSVG from "../svg/TrashSVG";
import ConfirmDeleteUser from "./ConfirmDeleteUser";
import EditUser from "./EditUser";
import DownArrow from "../svg/DownArrow";
import UpDownArrow from "../svg/UpDownArrow";
import AddUser from "./AddUser";

/**
 * UsersList component for the admin page. Displays a table of users, and allows an admin to add/remove users, as well as change user details.
 */

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(null);
  const [selectedUser, setSelectedUser] = useState({});
  const [rotate, setRotate] = useState("");
  const [showReset, setShowReset] = useState("hide");
  const { state } = useContext(StateContext);
  const ref = useRef(null);

  const getStudents = useCallback(async () => {
    if (state.currentUser.token) {
      setLoading(true);
      try {
        const data = await fetch(`${process.env.REACT_APP_API_URL}all_users`, {
          headers: {
            Authorization: "Bearer " + state.currentUser.token,
          },
        });
        const res = await data.json();
        if (!res.error) {
          setUsers(res);
        }
        setLoading(false);
      } catch (error) {
        console.error("error getting students: ", error);
        setLoading(false);
      }
    }
  }, [state.currentUser.token]);

  const showRole = (role) => {
    // 1 - admin, 2 - teacher, 3 - student, 4 - staff
    if (role === 1) {
      return "Admin";
    } else if (role === 2) {
      return "Teacher";
    } else if (role === 3) {
      return "Student";
    } else if (role === 4) {
      return "Staff";
    } else return "";
  };

  useEffect(() => {
    getStudents();
  }, [getStudents]);

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  const roleSort = (arr) => {
    setRotate(rotate === "flip" ? "no-flip" : "flip");
    setShowReset("");
    let newArray = [];
    arr.map((user) => newArray.push(user));
    rotate === "flip"
      ? newArray.sort((a, b) => a.role - b.role)
      : newArray.sort((a, b) => b.role - a.role);
    setFilteredUsers([...newArray]);
  };

  return (
    <div className="">
      {loading ? (
        <>
          <div className="loader flex flex-col items-center gap-4">
            <h2 className="mb-4 text-blueprimary-600 text-3xl">Loading...</h2>
            <BarLoader color="#4383cb" />
          </div>
        </>
      ) : (
        <div className="">
          <div className="flex items-end gap-4 mb-4">
            <SearchBar
              className="w-fit attendees-searchbar rounded-md shadow-md"
              placeholder="Search users..."
              searchFields={["first_name", "last_name", "email"]}
              inputArray={users}
              updateArray={(newArray) => setFilteredUsers([...newArray])}
              inputRef={ref}
            />
            <button
              type="button"
              className="bg-blueprimary-500 rounded-md shadow-md text-white px-2 py-1 hover:bg-blueprimary-600"
              onClick={() => {
                setShowModal("add");
              }}
            >
              Add User
            </button>
          </div>
          {showModal && (
            <CenteredModal
              className="users-list__modal"
              smallModal={true}
              onShowModal={() => {
                setShowModal();
                setSelectedUser({});
              }}
            >
              {showModal === "add" && (
                <AddUser
                  token={state.currentUser.token}
                  setUsers={setUsers}
                  setShowModal={setShowModal}
                  searchRef={ref}
                />
              )}
              {showModal === "edit" && (
                <EditUser
                  token={state.currentUser.token}
                  selectedUser={selectedUser}
                  setShowModal={setShowModal}
                  setUsers={setUsers}
                  searchRef={ref}
                />
              )}
              {showModal === "delete" && (
                <ConfirmDeleteUser
                  token={state.currentUser.token}
                  selectedUser={selectedUser}
                  setUsers={setUsers}
                  setShowModal={setShowModal}
                  searchRef={ref}
                />
              )}
            </CenteredModal>
          )}
          <table className="user-list__grid m-auto overflow-y-scroll scrollbar grid  border-grayprimary-500 border-b-transparent border shadow-md rounded-md overflow-hidde w-full">
            <thead className=" contents subgrid">
              <tr className="text-lg contents subgrid">
                <th className="border-grayprimary-500 border-b-2 p-2.5 grid place-items-start">
                  Email
                </th>
                <th className="border-grayprimary-500 border-b-2 p-2.5 grid place-items-start">
                  Name
                </th>
                <th className="border-grayprimary-500 border-b-2 p-2.5 place-items-start flex flex-row">
                  Grade
                </th>
                <th className="role-sort border-grayprimary-500 border-b-2 p-2.5 place-items-start flex flex-row">
                  <UpDownArrow
                    className={showReset}
                    onClick={() => {
                      setFilteredUsers(users);
                      setRotate("no-flip");
                      setShowReset("hide");
                    }}
                  />
                  <div
                    className="flex flex-row items-center"
                    onClick={() => roleSort(filteredUsers)}
                  >
                    Role
                    <DownArrow className={`${rotate} w-3 ml-2`} />
                  </div>
                </th>
                <th className="border-grayprimary-500 border-b-2 py-2.5 px-10 grid place-items-start">
                  {/* edit user */}
                </th>
                <th className="border-grayprimary-500 border-b-2 py-2.5 px-10 grid place-items-start">
                  {/* delete user */}
                </th>
              </tr>
            </thead>
            <tbody className="text-sm pt-4 contents subgrid">
              {filteredUsers?.map((user, index) => (
                <tr key={index} className="contents subgrid">
                  <td className="border-grayprimary-500 border-b p-2.5">
                    {user.email}
                  </td>
                  <td className="border-grayprimary-500 border-b p-2.5">
                    {user.last_name}, {user.first_name}
                  </td>
                  <td className="border-grayprimary-500 border-b p-2.5">
                    {user.grade_level && user.grade_level !== -1
                      ? user.grade_level
                      : "-"}
                  </td>
                  <td className="border-grayprimary-500 border-b p-2.5">
                    {showRole(user?.role)}
                  </td>
                  <td className="border-grayprimary-500 border-b p-2.5">
                    <span
                      className="underline text-blue-400 cursor-pointer"
                      onClick={() => {
                        setShowModal("edit");
                        setSelectedUser(user);
                      }}
                    >
                      Edit
                    </span>
                  </td>
                  <td className="border-grayprimary-500 border-b p-2.5">
                    <TrashSVG
                      className="user-delete__icon"
                      onClick={() => {
                        setShowModal("delete");
                        setSelectedUser(user);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
export default UsersList;
