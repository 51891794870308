import React, { useState, useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { StateContext } from "../contexts/State";
import Navbar from "../components/layouts/Navbar";
import { useDeviceDetect } from "../services/useDeviceDetect";
import Hamburger from "../components/svg/Hamburger";

/**
 * Attendance Page
 * Let teachers record attendance
 * @returns Attendance Page
 */
const Attendance = (props) => {
  const params = useParams();
  const { state } = useContext(StateContext);
  const [event, setEvent] = useState({});
  const [attendanceReport, setAttendanceReport] = useState([]);
  const [saved, setSaved] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);

  useEffect(() => {
    if (!state.currentUser.token) return;

    (async () => {
      const res = await fetch(
        process.env.REACT_APP_API_URL + "intervention/" + params.id,
        {
          headers: {
            Authorization: "Bearer " + state.currentUser.token,
          },
        }
      );
      const json = await res.json();
      setEvent(json);
    })();
    console.log("done");
  }, [state.currentUser.token, params.id]);

  useEffect(() => {
    if (!state.currentUser.token) return;

    (async () => {
      const res = await fetch(
        process.env.REACT_APP_API_URL + "get-attendance/" + params.id,
        {
          headers: {
            Authorization: "Bearer " + state.currentUser.token,
          },
        }
      );
      const json = await res.json();
      json.sort((a,b) => {
        if (a.student_lname.toLowerCase() > b.student_lname.toLowerCase()) return 1;
        if (a.student_lname.toLowerCase() < b.student_lname.toLowerCase()) return -1;
        return 0;
      })
      setAttendanceReport(json);
    })();
  }, [state.currentUser.token, params.id]);

  function onFormInput(e, i) {
    const value = e.target.checked ?? e.target.innerText;
    setAttendanceReport((prev) => {
      const temp = [...prev];
      temp[i][e.target.name || e.target.dataset.name] = value;
      return [...temp];
    });
  }
  function onSelectAll(e) {
    const updatedAttendance = attendanceReport.map((report) => {
      const value = e.target.checked ?? e.target.innerText;
      report[e.target.name || e.target.dataset.name] = value;
      return report;
    });
    console.log(updatedAttendance);
    setAttendanceReport([...updatedAttendance]);
  }

  async function saveFormInput() {
    if (!state.currentUser.token) return;

    await fetch(
      process.env.REACT_APP_API_URL +
        "intervention/record-attendance/" +
        params.id,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + state.currentUser.token,
        },
        body: JSON.stringify(attendanceReport),
      }
    );
    setSaved(true);
  }

  return (
    <section>
      <Navbar open={showNavbar} />
      <main
        className="dashboard-content h-screen"
        onClick={() => {
          if (showNavbar) setShowNavbar(false);
        }}
      >
        <Hamburger
          className="hamburger-icon px-4 py-4 h-10 w-10"
          onClick={() => {
            setShowNavbar(true);
          }}
        />
        <header className="flex flex-row items-center">
          <Link
            to="/dashboard"
            className="block border border-blueprimary-500 text-blueprimary-500 px-4 py-1.5 mr-3 rounded-md shadow-sm"
          >
            Back
          </Link>
          <h2 className="text-3xl my-4">
            Attendance for <b>{event.name}</b>
          </h2>
          <span className="ml-auto mr-3 text-greenprimary-500">
            {saved && "Attendance saved"}
          </span>
          <button
            className="block bg-blueprimary-500 text-white px-4 py-1.5 rounded-md shadow-sm"
            onClick={saveFormInput}
          >
            Save
          </button>
        </header>
        <table className="grid attendance-grid border-grayprimary-500 border-b-transparent border shadow-md rounded-md">
          <thead className="contents subgrid">
            <tr className="contents subgrid">
              <th className="border-grayprimary-500 border-b-4 p-2.5 grid place-items-center">
                <span>Student name</span>
                <input type="checkbox" className="block mx-auto invisible" />
              </th>
              <th className="border-grayprimary-500 border-b-4 p-2.5 grid place-items-center">
                <span>Absent</span>
                <input
                  type="checkbox"
                  name="attended"
                  className="block mx-auto"
                  onChange={(e) => onSelectAll(e)}
                />
              </th>
              <th className="border-grayprimary-500 border-b-4 p-2.5 grid place-items-center">
                <span>Tardy</span>
                <input
                  type="checkbox"
                  name="tardy"
                  className="block mx-auto"
                  onChange={(e) => onSelectAll(e)}
                />
              </th>
              <th className="border-grayprimary-500 border-b-4 p-2.5 grid place-items-center">
                <span>
                  Assessment <br /> Taken
                </span>
                <input
                  type="checkbox"
                  name="assessment"
                  className="block mx-auto"
                  onChange={(e) => onSelectAll(e)}
                />
              </th>
              <th className="border-grayprimary-500 border-b-4 p-2.5 grid place-items-center">
                <span>Mastered Skill</span>
                <input
                  type="checkbox"
                  name="mastered"
                  className="block mx-auto"
                  onChange={(e) => onSelectAll(e)}
                />
              </th>
              <th className="border-grayprimary-500 border-b-4 py-2.5 grid place-items-center">
                Score
              </th>
              <th className="border-grayprimary-500 border-b-4 p-2.5 grid place-items-center">
                Notes
              </th>
            </tr>
          </thead>
          <tbody className="contents subgrid">
            {attendanceReport?.map((attendee, i) => (
              <tr key={i} className="contents subgrid">
                <td className="border-grayprimary-500 border-b p-2.5">
                  <span>
                  {attendee.student_lname}, {attendee.student_fname}
                  </span>
                </td>
                <td className="border-grayprimary-500 border-b p-2.5">
                  <input
                    type="checkbox"
                    name="attended"
                    className="block mx-auto"
                    checked={attendee.attended}
                    onChange={(e) => onFormInput(e, i)}
                  />
                </td>
                <td className="border-grayprimary-500 border-b p-2.5">
                  <input
                    type="checkbox"
                    name="tardy"
                    className="block mx-auto"
                    checked={attendee?.tardy}
                    onChange={(e) => onFormInput(e, i)}
                  />
                </td>
                <td className="border-grayprimary-500 border-b p-2.5">
                  <input
                    type="checkbox"
                    name="assessment"
                    className="block mx-auto"
                    checked={attendee.assessment}
                    onChange={(e) => onFormInput(e, i)}
                  />
                </td>
                <td className="border-grayprimary-500 border-b p-2.5">
                  <input
                    type="checkbox"
                    name="mastered"
                    className="block mx-auto"
                    checked={attendee.mastered}
                    onChange={(e) => onFormInput(e, i)}
                  />
                </td>
                <td className="border-grayprimary-500 border-b p-2.5">
                  <input
                    type="number"
                    className="border-grayprimary-500 border block w-full bg-white px-1"
                    onChange={(e) =>
                      setAttendanceReport((prev) => {
                        const temp = [...prev];
                        temp[i].score = e.target.value;
                        return [...temp];
                      })
                    }
                    defaultValue={attendee.score}
                  />
                </td>
                <td className="border-grayprimary-500 border-b p-2.5 attendance-grid__notes">
                  <input
                    className="border-grayprimary-500 border block w-full bg-white px-1"
                    onChange={(e) =>
                      setAttendanceReport((prev) => {
                        const temp = [...prev];
                        temp[i].notes = e.target.value;
                        return [...temp];
                      })
                    }
                    defaultValue={attendee.notes}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </main>
    </section>
  );
};
export default Attendance;
