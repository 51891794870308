import React from "react";
import Button from "../components/Button";
import {useNavigate} from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="gap-6 text-center my-20">
                <img
                    src="/tss-transparent.png"
                    alt="tss-logo"
                    className="navbar-logo"
                    style={{ display: 'inline'}}
                />
                <h1>404 Not Found</h1><br />
                <h2>Sorry, the page you are looking for could not be found.</h2>
                <div style={{ marginTop: '150px' }}></div>
                <Button
                    id="logout-button"
                    className="logout-button"
                    type="button"
                    children={<>Go Back</>}
                    style={{
                        display: "inline",
                        backgroundColor: "hsl(212deg, 57%, 53%)"
                    }}
                    onClick={() => navigate(-1)}
                />
            </div>
        </>
    );
};

export default NotFound;