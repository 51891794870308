import React, { useState, useContext } from "react";
import Navbar from "../components/layouts/Navbar";
import { StateContext } from "../contexts/State";
import Hamburger from "../components/svg/Hamburger";
import ManageTimes from "../components/blockTimes/ManageTimes";
import RestrictedDays from "../components/blockTimes/RestrictedDays";

const BlockTimes = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const { state } = useContext(StateContext);
  return (
    <section>
      <Navbar open={showNavbar} />
      <div
        className="dashboard-content h-screen"
        onClick={() => {
          if (showNavbar) setShowNavbar(false);
        }}
      >
        <Hamburger
          className="hamburger-icon px-4 py-4 h-10 w-10"
          onClick={() => {
            setShowNavbar(true);
          }}
        />
        <h1 className="text-2xl text-gray-700">
          Manage Blocked Off Times and Days
        </h1>
        <div className="flex flex-wrap gap-8 mt-10">
          <ManageTimes
            currentUser={state?.currentUser}
            className="max-w-3xl mb-10"
          />
          <RestrictedDays
            currentUser={state?.currentUser}
            className="max-w-3xl"
          />
        </div>
      </div>
    </section>
  );
};
export default BlockTimes;
