import React from "react";
import DatePicker from "react-multi-date-picker";

const RepeatingInterventionEndType = ({
  endType,
  endCount,
  endDate,
  setRepeatingEventInfo,
  CustomInput,
  restrictedDays,
  DateMapper,
  setError = () => {},
  error = ""
}) => {
  return (
    <div>
      {endType === "date" ? (
        <div>
          <label
            htmlFor="date"
            className="block text-xl font-medium text-gray-700 mb-2"
          >
            End Date
          </label>
          <DatePicker
            format="YYYY-MM-DD"
            onChange={(dateObject) => {
              setRepeatingEventInfo((prev) => {
                const temp = { ...prev };
                temp.repeat_until_date = dateObject.format("YYYY-MM-DD");
                console.log(temp.repeat_until_date)
                return temp;
              });
              setError("")
            }}
            name="date"
            required
            value={endDate}
            render={<CustomInput value={endDate}/>}
            className="border-gray-300 border rounded-md px-3 py-2 ml-10 shadow-sm w-full form-control"
            style={{ borderWidth: "1px", borderColor: "rgb(209 213 219 / 1)" }}
            calendarPosition={"bottom-right"}
            offsetY={10}
            mapDays={({ date }) => {
              return DateMapper(date);
            }}
          />
        </div>
      ) : (
        <div>
          <label
            htmlFor="occurrences"
            className="block text-xl font-medium text-gray-700 mb-2"
          >
            How many occurrences?
          </label>
          <input
            required
            name="occurrences"
            min={2}
            max={365}
            value={endCount.toString()}
            type="number"
            className=" border-gray-300 border rounded-md px-3 py-2 shadow-sm w-full"
            onChange={(e) => {
              setRepeatingEventInfo((prev) => {
                const temp = { ...prev };
                temp.repeat_until_count = Number(e.target.value);
                return temp;
              });
            }}
          />
        </div>
      )}
    </div>
  );
};
export default RepeatingInterventionEndType;
