import { useEffect, useCallback, useState } from "react";
import SideArrowBtn from "../SideArrowBtn";
import useEmblaCarousel from "embla-carousel-react";
import PlaySVG from "../svg/PlaySVG";
import PauseSVG from "../svg/PauseSVG";

const PreviewImages = ({ className = "" }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
  const [pauseCarousel, setPauseCarousel] = useState(false);
  const [slides, setSlides] = useState([
    "./calendar-carousel.png",
    "./create-intervention-preview.png",
    "./intervention-details.png",
    "./reports.png",
  ]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    const slideIndex = emblaApi.selectedScrollSnap();
    setSelectedIndex(slideIndex);
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    emblaApi.on("select", onSelect);
    onSelect();
  }, [emblaApi, onSelect]);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  useEffect(() => {
    let interval = setInterval(() => {
      if (!pauseCarousel) {
        scrollNext();
      }
    }, 5000);

    if (interval && pauseCarousel) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [pauseCarousel, scrollNext, emblaApi]);
  return (
    <div className={`${className}`}>
      <h2 className="text-tssBlack-500 font-normal text-5xl leading-[60px]">
        Effortless, dynamic intervention scheduling.
      </h2>
      <h4 className="mt-2 font-light text-tssGray-600 leading-7 text-lg">
        We keep you organized, so you can focus on what matters most.
      </h4>
      <div className="mt-16 embla">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            {slides.map((slide, key) => (
              <div className="embla__slide " key={key}>
                <div className="w-[62.5rem] h-[33.563rem]">
                  <img
                    src={slides[key]}
                    alt="tss preview"
                    className="bg-[#F5F6F9] embla__slide__number  rounded-2xl p-2"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex  items-center justify-between mt-8 ">
          <div className="flex items-center gap-4">
            <div className=" bg-[#7A8DAA14] rounded-3xl flex px-4 py-[1.25rem] gap-3">
              {slides.map((slide, key) => (
                <div
                  style={{
                    backgroundColor:
                      key === selectedIndex ? "#273952" : "#C4C9D2",
                    width: key === selectedIndex ? "2rem" : "0.75rem",
                  }}
                  className={` h-3 rounded-full transition-all duration-300`}
                  key={key}
                ></div>
              ))}
            </div>
            <div className={`bg-[#7A8DAA14] rounded-full p-4 cursor-pointer`}>
              {pauseCarousel ? (
                <PlaySVG
                  className={""}
                  onClick={() => {
                    setPauseCarousel(!pauseCarousel);
                  }}
                />
              ) : (
                <PauseSVG
                  className={"text-tssBlack-500 w-[1.5rem] h-[1.5rem"}
                  onClick={() => {
                    setPauseCarousel(!pauseCarousel);
                  }}
                />
              )}
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <SideArrowBtn
              size="large"
              onClick={() => {
                scrollPrev();
              }}
            />
            <SideArrowBtn
              direction="right"
              size="large"
              onClick={() => {
                scrollNext();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default PreviewImages;
