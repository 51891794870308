import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate, Navigate } from "react-router-dom";
import { StateContext } from "./contexts/State";
function AdminRoutes({ adminOnly = false }) {
  const { state, dispatch } = useContext(StateContext);
  const [isUser, setIsUser] = useState(false);

  useEffect(() => {
    if (state?.currentUser?.user) {
      setIsUser(true);
    }
  }, [state?.currentUser?.user]);

  if (adminOnly) {
    if (isUser) {
      return state.currentUser.user &&
        state.currentUser.user?.role_name === "admin" ? (
        <Outlet />
      ) : (
        <Navigate to={"/dashboard"} />
      );
    } else {
      return <Outlet />;
    }
  } else {
    if (isUser) {
      return state.currentUser.user &&
        (state.currentUser.user?.role_name === "admin" ||
          state.currentUser.user?.role_name === "teacher") ? (
        <Outlet />
      ) : (
        <Navigate to={"/dashboard"} />
      );
    } else {
      return <Outlet />;
    }
  }
}

export default AdminRoutes;
