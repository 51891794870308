import React, { useState, useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { StateContext } from "../contexts/State";
import Navbar from "../components/layouts/Navbar";
import { useDeviceDetect } from "../services/useDeviceDetect";
import Hamburger from "../components/svg/Hamburger";
import DownArrow from "../components/svg/DownArrow";
import CenteredModal from "../components/CenteredModal";
import BarLoader from "react-spinners/BarLoader";

/**
 * Attendance Page
 * Let teachers record attendance
 * @returns Attendance Page
 */
const CSVUpload = (props) => {
  const { state } = useContext(StateContext);
  let [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  let [error, setError] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const [uploadedUsers, setUploadedUsers] = useState([]);
  let [showConfirm, setShowConfirm] = useState(false);

  function toggleCollapsible(e) {
    const rotator =
      e.currentTarget.parentElement.querySelector(".rotate").style;
    rotator.transform === "rotate(0deg)" || !rotator.transform
      ? (rotator.transform = "rotate(180deg)")
      : (rotator.transform = "rotate(0deg)");

    const bodyStyle =
      e.currentTarget.parentElement.querySelector(".body").style;
    if (bodyStyle.display === "block") {
      bodyStyle.display = "none";
    } else {
      bodyStyle.display = "block";
    }
  }
  async function saveFormInput(e) {
    try {
      setLoading(true);
      setError(false);
      e.preventDefault();
      if (!state.currentUser.token) return;

      const data = new FormData(e.target);

      const res = await fetch(process.env.REACT_APP_API_URL + "upload-users", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + state.currentUser.token,
        },
        body: data,
      });

      if (!res.ok) {
        throw new Error(res.statusText);
      }
      const newUsers = await res.json();
      setUploadedUsers(newUsers);
      setShowConfirm(true);
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  }

  async function confirmUpload(e) {
    try {
      setError(false);
      e.preventDefault();
      if (!state.currentUser.token) return;

      const res = await fetch(
        process.env.REACT_APP_API_URL + "upload-users/1",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + state.currentUser.token,
          },
        }
      );
      setShowConfirm(false);
      setSaved(true);
    } catch (e) {
      setError("There was an error. Please try again");
    }
  }

  return (
    <section>
      <Navbar open={showNavbar} />
      <main
        className="dashboard-content h-screen"
        onClick={() => {
          if (showNavbar) setShowNavbar(false);
        }}
      >
        <Hamburger
          className="hamburger-icon px-4 py-4 h-10 w-10"
          onClick={() => {
            setShowNavbar(true);
          }}
        />
        <header className="flex flex-col w-3/4 mb-6">
          <h2 className="text-3xl my-4">CSV Upload</h2>
          <p>
            Note: When uploading a roster, existing users that are updated from
            uploading the new roster will <b>not</b> have their passwords changed.
            If you want to change a user's password, please go to the Add/Edit Users page.
          </p>
        </header>
        <div className="mb-4">
          Download:
          <a
            href="/csv_upload.csv"
            target="blank"
            className="bg-blueprimary-500 text-white px-4 py-1.5 rounded-md shadow-sm ml-4"
          >
            Template (.csv)
          </a>
          <a
            href="/csv_upload_instructions.xlsx"
            target="blank"
            className="bg-blueprimary-500 text-white px-4 py-1.5 rounded-md shadow-sm ml-4"
          >
            Instructions (.xlsx)
          </a>
        </div>
        <form onSubmit={saveFormInput}>
          <input
            type="file"
            name="csv_upload"
            accept=".csv"
            disabled={loading}
          />
          <button
            disabled={loading}
            className="bg-blueprimary-500 disabled:bg-gray-300 text-white px-4 py-1.5 rounded-md shadow-sm ml-4"
          >
            Upload
          </button>
          {loading && !saved && !error && (
            <>
              <span className="block ml-3">Uploading students...</span>
              <BarLoader
                color={"hsl(212, 57%, 53%)"}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </>
          )}
          {saved && (
            <span className="inline-block ml-3 text-greenprimary-500">
              Upload Complete
            </span>
          )}
          {error && (
            <span className="inline-block ml-3 text-redprimary-500">
              {error}. Please try again.
            </span>
          )}
        </form>
        {showConfirm && (
          <CenteredModal>
            <h3 className="text-2xl text-greenprimary-500 mb-2 border-b border-gray-200">
              File upload successful
            </h3>
            <section className="collapsible flex flex-col">
              <button
                className="text-left flex flex-row w-full justify-between border-b border-gray-200"
                onClick={toggleCollapsible}
              >
                <h4 className="text-xl mb-2">
                  Users successfully uploaded. {uploadedUsers?.delete?.length}{" "}
                  users were not included in the CSV. Delete them? This action
                  cannot be undone.
                </h4>
                <DownArrow className="rotate w-3" style="transform: rotate(0deg)" />
              </button>
              <div className="body hidden">
                <ul className="list-disc pl-8">
                  {uploadedUsers?.delete?.map((account, key) => (
                    <li key={key}>{account}</li>
                  ))}
                </ul>
              </div>
            </section>
            <section className="collapsible flex flex-col">
              <button
                className="text-left flex flex-row w-full justify-between border-b border-gray-200"
                onClick={toggleCollapsible}
              >
                <h4 className="text-xl mb-2">
                  {uploadedUsers?.errors?.length} of {uploadedUsers?.total}{" "}
                  users uploaded with errors
                </h4>
                <DownArrow className="rotate w-3" style="transform: rotate(0deg)" />
              </button>
              <div className="body hidden">
                <ul className="list-disc pl-8">
                  {uploadedUsers?.errors?.map((error, key) => (
                    <li key={key}>
                      {error?.email} - {error?.error}
                    </li>
                  ))}
                </ul>
              </div>
            </section>
            <div className="w-fit mx-auto text-center">
              <button
                className="button-component inline-block m-2 bg-redprimary-500"
                onClick={confirmUpload}
              >
                Yes, delete {uploadedUsers?.delete?.length} users
              </button>
              <button
                className="button-component inline-block m-2 bg-grayprimary-500"
                onClick={() => setShowConfirm(false)}
              >
                No, don't delete
              </button>
            </div>
          </CenteredModal>
        )}
      </main>
    </section>
  );
};
export default CSVUpload;
