import {
  BrowserRouter,
  Routes as Switch,
  Route,
  Navigate,
} from "react-router-dom";
import App from "./App";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Admin from "./pages/Admin";
import Attendance from "./pages/Attendance";
import Reports from "./pages/Reports";
import CSVUpload from "./pages/CSVUpload";
import NotFound from "./pages/NotFound";
import AdminRoutes from "./AdminRoutes";
import Home from "./pages/Home";
import BlockTimes from "./pages/BlockTimes";
import Settings from "./pages/Settings";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route element={<AdminRoutes adminOnly={true} />}>
          <Route path="/admin" element={<Admin />} />
          <Route path="/block-off-times" element={<BlockTimes />} />
        </Route>
        <Route element={<AdminRoutes />}>
          <Route path="/csv-upload" element={<CSVUpload />} />
          <Route path="/intervention/:id/attendance" element={<Attendance />} />
          <Route path="/reports" element={<Reports />} />
        </Route>
        <Route path="/" element={<App />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/settings" element={<Settings />} />
          <Route index element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/csv-upload" element={<CSVUpload />} />
          <Route path="/intervention/:id/attendance" element={<Attendance />} />
          <Route path="/home" element={<Home />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Switch>
    </BrowserRouter>
  );
}
