import React from "react";
import DownArrow from "./svg/DownArrow";

const SideArrowBtn = ({
  onClick = () => {},
  direction = "left",
  size = "normal",
}) => {
  return (
    <button
      className={`flex items-center justify-center text-tssBlack-500 bg-[#3E6DB114] ${
        size === "large" ? "w-12 h-12" : "w-9 h-9"
      } rounded-full hover:text-[#EBEEF4] hover:bg-tssBlack-500`}
      onClick={onClick}
    >
      <DownArrow
        className={` ${size === "large" ? "w-5" : "w-4"} ${
          direction === "left" ? "rotate-90" : "rotate-[270deg]"
        } text-center `}
      />
    </button>
  );
};
export default SideArrowBtn;
