import React, { useRef, useState, useEffect } from "react";
import SearchBar from "../SearchBar";
import PlusSVG from "../svg/PlusSVG";

const SettingsAllStudents = ({
  students,
  excludedStudents,
  setExcludedStudents,
  setChangesMade,
}) => {
  const [filteredStudents, setFilteredStudents] = useState([]);
  const ref = useRef(null);

  useEffect(() => {
    setFilteredStudents(students);
  }, [students]);

  function isExcluded(student) {
    if (excludedStudents && excludedStudents.length > 0) {
      if (
        excludedStudents.find(
          (excludedStudent) => excludedStudent?.id === student?.id
        )
      ) {
        return true;
      } else return false;
    } else return false;
  }

  return (
    <div className="flex flex-col gap-4 mb-4">
      <div className="flex gap-2 items-end justify-between">
        <h3 className="font-semibold text-gray-800">All Students</h3>
        <SearchBar
          className="w-fit attendees-searchbar rounded-md shadow-md"
          placeholder="Search students..."
          searchFields={["first_name", "last_name", "email"]}
          inputArray={students}
          updateArray={(newArray) => setFilteredStudents([...newArray])}
          inputRef={ref}
          id="all-students-searchbar"
        />
      </div>
      <table className="settings-students-list__grid  overflow-y-scroll scrollbar grid  border-grayprimary-500 border-b-transparent border shadow-md rounded-md overflow-hidden w-full">
        <thead className=" contents subgrid">
          <tr className="text-lg contents subgrid">
            <th className="text-gray-800 border-grayprimary-500 border-b-2 p-2.5 grid place-items-start text-center">
              Email
            </th>
            <th className="text-gray-800 border-grayprimary-500 border-b-2 p-2.5 grid place-items-start text-center">
              Name
            </th>
            <th className="text-gray-800 border-grayprimary-500 border-b-2 p-2.5 place-items-start  text-center">
              Grade
            </th>
            <th className="text-gray-800 border-grayprimary-500 border-b-2 p-2.5 place-items-start  text-center">
              Add
            </th>
          </tr>
        </thead>
        <tbody className="text-sm pt-4 contents subgrid">
          {filteredStudents?.map((user, index) => (
            <tr key={index} className="contents subgrid">
              <td
                className={`border-grayprimary-500 border-b p-2.5 ${
                  isExcluded(user) ? "text-gray-400" : ""
                }`}
              >
                {user.email}
              </td>
              <td
                className={`border-grayprimary-500 border-b p-2.5 ${
                  isExcluded(user) ? "text-gray-400" : ""
                }`}
              >
                {user.last_name}, {user.first_name}
              </td>
              <td
                className={`border-grayprimary-500 border-b p-2.5 ${
                  isExcluded(user) ? "text-gray-400" : ""
                } text-center flex items-center justify-center`}
              >
                {user.grade_level && user.grade_level !== -1
                  ? user.grade_level
                  : "-"}
              </td>
              <td className="border-grayprimary-500 border-b p-2.5 flex items-center justify-center">
                <button
                  type="button"
                  disabled={isExcluded(user)}
                  onClick={() => {
                    setExcludedStudents((prev) => [...prev, user]);
                    setChangesMade(true);
                    if (ref.current.value?.length > 0) {
                      ref.current.value = "";
                      setFilteredStudents(students);
                    }
                  }}
                >
                  <PlusSVG
                    className={`${
                      isExcluded(user)
                        ? "text-gray-200"
                        : "text-greenprimary-500"
                    }`}
                  />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default SettingsAllStudents;
