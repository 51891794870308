import React, { useState, useEffect } from "react";

const RepeatingSelectDays = ({
  className = "",
  setRepeatingEventInfo = () => {},
  interventionDate = "",
  setError = () => {},
  error = "",
}) => {

  const [repeatDays, setRepeatDays] = useState([]);
  const days = ["Mon", "Tue", "Wed", "Thu", "Fri"];

  useEffect(() => {
    setRepeatingEventInfo((prev) => {
      const temp = { ...prev };
      temp.days_of_week = repeatDays.sort();
      return temp;
    });
  }, [repeatDays, setRepeatingEventInfo]);

  useEffect(() => {
    console.log(repeatDays.sort());
  }, [repeatDays]);

  return (
    <div
      className={`${className} flex gap-2  p-2  ${
        error ? "border border-red-400 rounded-md" : ""
      }`}
    >
      {days.map((day, key) => (
        <button
          key={key}
          className={` ${
            repeatDays.includes(key) ? "text-gray-800" : "text-gray-300"
          }`}
          type="button"
          onClick={() => {
            setError("");
            if (repeatDays.includes(key)) {
              setRepeatDays((prev) => {
                const temp = [...prev];
                temp.splice(temp.indexOf(key), 1);
                return temp;
              });
            } else {
              setRepeatDays(() => [...repeatDays, key]);
            }
          }}
        >
          {day}
        </button>
      ))}
    </div>
  );
};
export default RepeatingSelectDays;
