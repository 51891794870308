import React, { useEffect, useCallback, useState } from "react";
import ManageTimeInput from "../admin/ManageTimeInput";
import CenteredModal from "../CenteredModal";
import RestrictTeacherAccess from "./RestrictTeacherAccess";

const ManageTimes = ({ currentUser = {}, ...props }) => {
  const [times, setTimes] = useState({
    Monday: ["00:00", "00:01"],
    Tuesday: ["00:00", "00:01"],
    Wednesday: ["00:00", "00:01"],
    Thursday: ["00:00", "00:01"],
    Friday: ["00:00", "00:01"],
    Saturday: ["00:00", "00:01"],
    Sunday: ["00:00", "00:01"],
  });
  const [saving, setSaving] = useState("Save");
  const [showEditModal, setShowEditModal] = useState(false);

  const getTimes = useCallback(async () => {
    if (currentUser?.token) {
      try {
        const data = await fetch(
          `${process.env.REACT_APP_API_URL}school/time-limits`,
          {
            headers: {
              Authorization: "Bearer " + currentUser?.token,
            },
          }
        );
        const res = await data.json();
        if (!res.error) {
          if (typeof res === "object") {
            for (let day in res) {
              if (res?.[day]?.[0] && res?.[day]?.[1]) {
                continue;
              } else {
                res[day] = ["00:00", "00:01"];
              }
            }
            setTimes(res);
          }
        }
      } catch (error) {
        console.error("error getting signup times: ", error);
      }
    }
  }, [currentUser?.token]);

  useEffect(() => {
    getTimes();
  }, [getTimes]);

  const submitTimes = async () => {
    try {
      setSaving("Saving...");
      const data = await fetch(
        `${process.env.REACT_APP_API_URL}school/time-limits/${currentUser?.user?.school}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + currentUser?.token,
          },
          body: JSON.stringify(times),
        }
      );
      const res = await data.json();
      if (!res.error) {
        console.log("success");
        setSaving("Saved!");
        setShowEditModal(false);
      }
    } catch (error) {
      console.error("error getting signup times: ", error);
    }
  };

  return (
    <div
      className="flex flex-col gap-2 p-2 my-4 border-b border-gray-300 rounded-md"
      {...props}
    >
      <RestrictTeacherAccess currentUser={currentUser} />
      <p className="font-semibold text-gray-700">
        Block a time off where students can't select interventions
      </p>
      <span className="text-base self-start text-gray-800">
        You may select times to block off for each day of the week. (By default,
        students can select interventions at all times of the day.)
      </span>
      {saving === "Saved!" && (
        <span className="text-green-400">Times saved successfully!</span>
      )}
      <div className="flex items-center"></div>
      <span className="text-sm text-gray-400 block self-start">
        Hint: click the "clock" icon to show a dropdown menu to help make your
        selection.
      </span>
      <div className="flex flex-col gap-4 items-start w-full mt-4">
        <ManageTimeInput
          setSaving={setSaving}
          day={"Monday"}
          timesToday={times?.Monday}
          setTimes={setTimes}
        />
        <ManageTimeInput
          setSaving={setSaving}
          day={"Tuesday"}
          timesToday={times?.Tuesday}
          setTimes={setTimes}
        />
        <ManageTimeInput
          setSaving={setSaving}
          day={"Wednesday"}
          timesToday={times?.Wednesday}
          setTimes={setTimes}
        />
        <ManageTimeInput
          setSaving={setSaving}
          day={"Thursday"}
          timesToday={times?.Thursday}
          setTimes={setTimes}
        />
        <ManageTimeInput
          setSaving={setSaving}
          day={"Friday"}
          timesToday={times?.Friday}
          setTimes={setTimes}
        />
        <ManageTimeInput
          setSaving={setSaving}
          day={"Saturday"}
          timesToday={times?.Saturday}
          setTimes={setTimes}
        />
        <ManageTimeInput
          setSaving={setSaving}
          day={"Sunday"}
          timesToday={times?.Sunday}
          setTimes={setTimes}
        />
      </div>
      <div className="self-end flex justify-end items-center gap-4 mt-6 px-2">
        <button
          disabled={saving === "Save" ? false : true}
          type="button"
          className={`rounded-md shadow-md text-white px-6 py-2 disabled:bg-gray-300 ${
            saving === "Save"
              ? "bg-blueprimary-500 hover:bg-blueprimary-600"
              : ""
          } `}
          onClick={() => {
            submitTimes();
          }}
        >
          {saving}
        </button>
      </div>
    </div>
  );
};
export default ManageTimes;
