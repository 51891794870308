import React, { useEffect, useCallback, useState } from "react";
import DatePicker from "react-multi-date-picker";
import CenteredModal from "../CenteredModal";
import DownArrow from "../svg/DownArrow";
import TrashSVG from "../svg/TrashSVG";

const MultipleInterventionDates = ({
  currentUser = {},
  multiInterventionDates = [],
  setMultiInterventionDates = () => {},
  ...props
}) => {
  const [saving, setSaving] = useState("Save");
  const [showEditModal, setShowEditModal] = useState(false);
  const [pastDaysToggle, setPastDaysToggle] = useState(false);
  const [pastDatesToggle, setPastDatesToggle] = useState(false);
  const [rotate, setRotate] = useState("");
  const [selectedDates, setSelectedDates] = useState([]);
  const [removeMultiInterventionDays, setRemoveMultiInterventionDays] = useState([]);

  const removeDay = (index) => {
    if (saving !== "Save") {
      setSaving("Save");
    }
    setMultiInterventionDates((prev) => {
      const temp = [...prev];
      temp.splice(index, 1);
      return temp;
    });
    setRemoveMultiInterventionDays((prev) => {
      const temp = [...prev];
      temp.push(multiInterventionDates.at(index));
      return temp;
    });
  };

  const submitDays = async () => {
    if (selectedDates.length < 1 && removeMultiInterventionDays <= 1) {
      setShowEditModal(false);
      setSaving("Saved!");
      setRemoveMultiInterventionDays([]);
      setSelectedDates([]);
      return;
    }
    if (removeMultiInterventionDays.length >= 1) {
      removeMultiInterventionDays.map((date) => {
        let tempDate = new Date(date + "T00:00:00").toISOString().split("T")[0];
        selectedDates.map((day) => {
          let tempDay = day.format("YYYY-MM-DD");
          if (tempDate === tempDay) {
            setRemoveMultiInterventionDays((prev) => {
              const temp = [...prev];
              temp.splice(temp.indexOf(date), 1);
              return temp;
            });
          }
        });
      });
      let dates = removeMultiInterventionDays.map(
        (day) => new Date(day + "T00:00:00").toISOString().split("T")[0]
      );
      try {
        const data = await fetch(
          `${process.env.REACT_APP_API_URL}school/multiple-intervention-date/delete`,
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + currentUser?.token,
            },
            body: JSON.stringify(dates),
          }
        );
        const res = await data.json();
        if (!res.error) {
          console.log("successfully deleted restricted days");
          setShowEditModal(false);
          setSaving("Saved!");
          setRemoveMultiInterventionDays([]);
        }
      } catch (error) {
        console.error("error deleting days: ", error);
      }
    }
    if (selectedDates.length >= 1) {
      try {
        let datesToSend = [];
        selectedDates.map((date) => {
          date = date.format("YYYY-MM-DD");
          datesToSend.push(date);
        });
        setSaving("Saving...");
        const data = await fetch(
          `${process.env.REACT_APP_API_URL}school/multiple-intervention-date/${currentUser.user.school}`,
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + currentUser?.token,
            },
            body: JSON.stringify(datesToSend),
          }
        );
        const res = await data.json();
        if (!res.error) {
          console.log("successfully added multi-intervention day");
          setShowEditModal(false);
          setSaving("Saved!");
          setMultiInterventionDates((prev) => {
            const temp = [...prev];
            selectedDates.map((day) => {
              const date = day.format("YYYY-MM-DD");
              temp.push(date);
            });
            temp.sort((a, b) => {
              if (a < b) {
                return -1;
              } else return 1;
            });
            return temp;
          });
        }
      } catch (error) {
        console.error("error adding restricted dates: ", error);
      }
    }
    setSelectedDates([]);
  };

  function CustomInput({ openCalendar, value, handleValueChange }) {
    return (
      <input
        name="date"
        placeholder="mm/dd/yyyy"
        onFocus={openCalendar}
        required
        readOnly
        value={selectedDates}
        className=" border-gray-300 border rounded-md px-3 py-2 shadow-sm w-full"
      />
    );
  }

  function DateMapper(date) {
    let res;
    multiInterventionDates.forEach((day) => {
      if (date.format("YYYY-MM-DD") === new Date(day + "T00:00:00").toISOString().split("T")[0]) {
        res = {
          disabled: true,
          style: { color: "#ccc" },
        };
      }
    });
    return res;
  }

  return (
    <div className="flex flex-col gap-2 my-10" {...props}>
      {saving === "Saved!" && (
        <p className="text-green-400 text-sm">Days saved successfully!</p>
      )}
      {saving === "Saving..." && (
        <p className="text-blue-400 text-sm">Saving Days...</p>
      )}
      <div className="flex flex-col items-start">
      <p className="text-sm text-tssGray-600 mb-2">Past and Current multi-intervention days for the next 90 days will be displayed on this page.</p>
        <div className="flex justify-between w-full flex-row-reverse gap-8">
          <div>
            <span className="font-semibold text-tssBlack-500">
              Select Multi-Intervention Days
            </span>
            <label
              htmlFor="date"
              className="block text-sm font-light text-gray-500 mb-2"
            >
              Select One or Multiple Days
            </label>
            <button
              disabled={saving === "Save" ? false : true}
              type="button"
              className={`rounded-md shadow-md text-white px-6 py-2 mr-4 disabled:bg-gray-300 ${
                saving === "Save"
                  ? "bg-blueprimary-500 hover:bg-blueprimary-600"
                  : ""
              } `}
              onClick={() => {
                setSelectedDates([]);
                setRemoveMultiInterventionDays([]);
                submitDays();
              }}
            >
              {saving}
            </button>
            <DatePicker
              multiple
              format="MM/DD/YYYY"
              value={selectedDates}
              name="date"
              render={<CustomInput />}
              className="border-gray-300 border rounded-md px-3 py-2 shadow-sm w-full form-control"
              style={{
                borderWidth: "1px",
                borderColor: "rgb(209 213 219 / 1)",
              }}
              onOpen={() => {
                setSelectedDates([]);
              }}
              onChange={(e) => {
                setSelectedDates(e);
                setSaving("Save");
              }}
              calendarPosition={"bottom-right"}
              offsetY={10}
              mapDays={({ date }) => {
                return DateMapper(date);
              }}
            />
          </div>
          <div className="flex flex-col">
            {multiInterventionDates.length === 0 && (
              <>
                No days have been set for multi-intervention
                signups/assignments.
              </>
            )}
            {multiInterventionDates.length > 0 && (
              <>
                <p className="font-semibold text-tssBlack-500">
                  Current Multi-Intervention Days:
                </p>
                <ul>
                  {multiInterventionDates.map((day) => {
                    if (pastDatesToggle === false && new Date(day + "T00:00:00") < new Date())
                      setPastDatesToggle(true);
                    return new Date(day + "T00:00:00") >= new Date() ? (
                      <li
                        className="w-full flex flex-row justify-between mt-1.5 text-sm"
                        key={day}
                      >
                        {new Date(day + "T00:00:00").toDateString()}
                        <TrashSVG
                          className="text-red-700 cursor-pointer w-4 h-4"
                          onClick={() => {
                            removeDay(multiInterventionDates.indexOf(day));
                          }}
                        />
                      </li>
                    ) : null;
                  })}
                </ul>
                {pastDatesToggle && (
                  <div
                    className="flex flex-row justify-between gap-2 mt-2"
                    style={{ width: "17rem" }}
                  >
                    <div className="font-semibold text-tssBlack-500">
                      {pastDaysToggle ? "Hide" : "Show"} Past Multi-Intervention
                      Days:
                    </div>
                    <DownArrow
                      className={`${rotate} w-3`}
                      onClick={() => {
                        setRotate(rotate == "no-flip" ? "flip" : "no-flip");
                        setPastDaysToggle(!pastDaysToggle);
                      }}
                    />
                  </div>
                )}
                {pastDaysToggle && (
                  <>
                    <ul>
                      {multiInterventionDates.map((day) => {
                        return new Date(day + "T00:00:00") < new Date() ? (
                          <li
                            className="w-full flex flex-row justify-between mt-1.5 text-sm"
                            key={day}
                          >
                            {new Date(day + "T00:00:00").toDateString()}
                            <TrashSVG
                              className="text-red-700 cursor-pointer w-4 h-4"
                              onClick={() => {
                                removeDay(multiInterventionDates.indexOf(day));
                              }}
                            />
                          </li>
                        ) : null;
                      })}
                    </ul>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultipleInterventionDates;
