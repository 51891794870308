import React, { useEffect } from "react";
import RepeatingInterventionEndType from "./RepeatingInterventionEndType";
import RepeatingSelectDays from "./RepeatingSelectDays";

const RepeatingInterventionForm = ({
  setRepeatingEventInfo = () => {},
  repeatingEventInfo = {},
  CustomInput,
  restrictedDays,
  DateMapper,
  interventionDate,
  setError = () => {},
  error = "",
}) => {
  // daily OR  weekly, on the same day of the week
  // set termination details:
  // end after a certain number occurrences
  // end ON or BEFORE a certain date (date field?)

  // save attendees with event, or just the event itself (repeat-content: "details", "all")

  useEffect(() => {
    return () => {
      setRepeatingEventInfo((prev) => {
        const temp = { ...prev };
        temp.repeat_type = "daily";
        return temp;
      });
    };
  }, [setRepeatingEventInfo]);
  return (
    <div className="flex flex-col gap-4">
      <fieldset
        className=""
        onChange={(e) => {
          setRepeatingEventInfo((prev) => {
            const temp = { ...prev };
            temp.repeat_type = e.target.value;
            return temp;
          });
        }}
      >
        <legend className="block text-xl font-medium text-gray-700 mb-2">
          Should this intervention be daily, weekly, or custom?
        </legend>
        <div className="flex gap-6 flex-wrap ">
          <div className="flex gap-2 items-center p-2">
            <label className="text-base" htmlFor="daily">
              Daily
            </label>
            <input
              type="radio"
              id="daily"
              value="daily"
              defaultChecked={true}
              name="repeat-type"
            />
            <label className="text-base" htmlFor="weekly">
              Weekly
            </label>
            <input type="radio" id="weekly" value="weekly" name="repeat-type" />
            <label className="text-base" htmlFor="custom">
              Custom
            </label>
            <input type="radio" id="custom" value="custom" name="repeat-type" />
          </div>
          {repeatingEventInfo.repeat_type === "custom" && (
            <RepeatingSelectDays
              interventionDate={interventionDate}
              setRepeatingEventInfo={setRepeatingEventInfo}
              setError={setError}
              error={error}
            />
          )}
        </div>
      </fieldset>
      <fieldset
        onChange={(e) => {
          setRepeatingEventInfo((prev) => {
            const temp = { ...prev };
            temp.endType = e.target.value;
            return temp;
          });
        }}
      >
        <legend className="block text-xl font-medium text-gray-700 mb-2">
          When should this intervention end?
        </legend>
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <label className="text-base" htmlFor="occurrence">
              After (x) occurrences
            </label>
            <input
              type="radio"
              id="occurrence"
              value="occurrence"
              name="end-type"
            />
          </div>
          <div className="flex items-center gap-2">
            <label className="text-base" htmlFor="date">
              On a specific Date
            </label>
            <input
              defaultChecked={true}
              type="radio"
              id="date"
              value="date"
              name="end-type"
            />
          </div>
        </div>
      </fieldset>
      <RepeatingInterventionEndType
        endType={repeatingEventInfo.endType}
        endDate={repeatingEventInfo.repeat_until_date}
        endCount={repeatingEventInfo.repeat_until_count}
        setRepeatingEventInfo={setRepeatingEventInfo}
        CustomInput={CustomInput}
        DateMapper={DateMapper}
        restrictedDays={restrictedDays}
        setError={setError}
        error={error}
      />
      <fieldset
        className="w-80"
        onChange={(e) => {
          setRepeatingEventInfo((prev) => {
            const temp = { ...prev };
            temp.repeat_content = e.target.value;
            return temp;
          });
        }}
      >
        <legend className="block text-xl font-medium text-gray-700 mb-2">
          Do you want to save the attendees for future occurrences of this
          intervention?
        </legend>
        <div className="flex gap-2 items-center w-fit">
          <label className="text-base" htmlFor="all">
            Yes
          </label>
          <input
            type="radio"
            id="all"
            value="all"
            name="repeat-content"
            defaultChecked={
              repeatingEventInfo.repeat_content === "all" ? true : false
            }
          />
          <label className="text-base" htmlFor="details">
            No
          </label>
          <input
            type="radio"
            id="details"
            value="details"
            defaultChecked={
              repeatingEventInfo.repeat_content === "details" ? true : false
            }
            name="repeat-content"
          />
        </div>
      </fieldset>
      {/* {repeatingEventInfo.repeat_content === "details" && (
        <span className="text-sm text-red-500">Note: when you select "No" for the above item, you will have to manually add attendees <br /> to each intervention after it is created.</span>
      )} */}
    </div>
  );
};
export default RepeatingInterventionForm;
