import { useState, useEffect, useCallback } from "react";
import MultiInterventionSVG from "../svg/MultiInterventionSVG";

const DayHeader = ({
  date,
  label,
  restrictedDays,
  multiInterventionSignups = false,
  multiInterventionAssignments = false,
  userRole = 3,
  multiInterventionDates = [],
}) => {
  const today = new Date();
  const [isRestricted, setIsRestricted] = useState(false);
  const [isMultiDay, setIsMultiDay] = useState(false);
  let currMonth = date.getMonth() === today.getMonth();
  let highlightDate = date.getDate() === today.getDate();

  var dateString = date.toISOString().split("T")[0];
  useEffect(() => {
    if (restrictedDays.includes(dateString)) {
      setIsRestricted(true);
    }
  }, []);

  const isMultiIntDay = useCallback(() => {
    if (multiInterventionDates.length > 0) {
      if (
        multiInterventionDates.includes(
          new Date(date).toISOString().slice(0, 10)
        )
      ) {
        setIsMultiDay(true);
      }
    }
  }, [date, multiInterventionDates]);

  useEffect(() => {
    isMultiIntDay();
  }, [isMultiIntDay]);

  return (
    <div className="w-full m-2 flex items-center justify-between">
      <div className={``}>
        {currMonth ? (
          <p
            className={` relative  top-1
          ${isRestricted ? "rbc-off-range " : ""} ${
              highlightDate ? "text-[#3E6DB1] font-medium" : ""
            }`}
          >
            {label}
          </p>
        ) : (
          <p
            className={`text-gray-300 relative  top-1
          ${isRestricted ? "rbc-off-range " : ""}`}
          >
            {label}
          </p>
        )}
      </div>
      {userRole === 3 && isMultiDay && (
        <MultiInterventionSVG className="w-5 h-5 block mx-4 text-tssGray-600" />
      )}
      {userRole !== 3 && isMultiDay && (
        <MultiInterventionSVG className="w-5 h-5 block mx-4 text-tssGray-600" />
      )}
    </div>
  );
};
export default DayHeader;
