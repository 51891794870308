import React, { useState, useEffect } from "react";
import { debug } from "util";
import SmallTextInput from "../SmallTextInput";

const AddUser = ({
  token = "",
  setShowModal = {},
  setUsers = {},
  searchRef = null,
}) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState(3);
  const [grade, setGrade] = useState(-1);
  const [showError, setShowError] = useState(null);

  async function createUser() {
    if (password && email && firstName && lastName) {
      try {
        const res = await fetch(
          process.env.REACT_APP_API_URL + "users/create",
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
              email,
              password,
              first_name: firstName,
              last_name: lastName,
              grade_level: grade,
              role: role,
            }),
          }
        );
        const data = await res.json();
        data.role = role;
        if (!res.ok) throw new Error(data.error);
        setUsers((prev) => {
          return [...prev, data];
        });
        searchRef.current.value = null;
        setShowModal(null);
      } catch (e) {
        setShowError(e.message);
      }
    } else {
      setShowError("You must fill out all fields to create a user.");
    }
  }

  useEffect(() => {
    if (role === 3) {
      setGrade(6);
    } else {
      setGrade(-1);
    }
  }, [role]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        createUser();
      }}
      className="w-96"
    >
      <h4 className="text-2xl">Create a new user</h4>
      <div className="flex flex-col items-start mt-4">
        <label htmlFor="select-user-role" className="font-bold text-base">
          Select a role for this user:
        </label>
        <select
          name="select-user-role"
          className="text-sm px-2 py-1 border border-gray-400 rounded-md shadow-md"
          onChange={(e) => {
            setRole(+e.target.value);
          }}
        >
          <option value="3">Student</option>
          <option value="2">Teacher</option>
          <option value="1">Admin</option>
          <option value="4">Staff</option>
        </select>
      </div>
      {grade !== -1 && (
        <div className="flex flex-col items-start mt-4">
          <label htmlFor="select-user-grade" className="font-bold text-base">
            Select a grade for this user:
          </label>
          <select
            name="select-user-grade"
            className="text-sm px-2 py-1 border border-gray-400 rounded-md shadow-md"
            onChange={(e) => {
              setGrade(+e.target.value);
            }}
          >
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </select>
        </div>
      )}
      <div className="flex flex-col gap-2 items-stretch mt-4 text-sm">
        <SmallTextInput
          className="w-full"
          id="first-name"
          label="First name"
          type="text"
          placeholder="First name"
          onChange={(e) => {
            setFirstName(e.target.value);
            setShowError(null);
          }}
          onBlur={() => {}}
          hasError={false}
          errorMessage={showError}
        />
        <SmallTextInput
          className="w-full"
          id="last-name"
          label="Last name"
          type="text"
          placeholder="Last name"
          onChange={(e) => {
            setLastName(e.target.value);
            setShowError(null);
          }}
          onBlur={() => {}}
          hasError={false}
          errorMessage={showError}
        />
      </div>
      <div className="flex flex-col gap-2 items-stretch mt-4 text-sm">
        <SmallTextInput
          className="w-full"
          id="new-email"
          label="Email"
          type="email"
          placeholder="New email"
          onChange={(e) => {
            setEmail(e.target.value);
            setShowError(null);
          }}
          onBlur={() => {}}
          hasError={false}
          errorMessage={showError}
        />
        <SmallTextInput
          className="w-full"
          id="new-user-password"
          label="Password"
          type="password"
          placeholder="New password"
          onChange={(e) => {
            setPassword(e.target.value);
            setShowError(null);
          }}
          onBlur={() => {}}
          hasError={false}
          errorMessage={showError}
        />
        {showError && (
          <span className="text-redprimary-500 mx-auto">{showError}</span>
        )}
      </div>
      <div className="mt-4 flex items-center justify-between">
        <button
          type="button"
          className="text-sm bg-gray-500 rounded-md shadow-md px-2 py-1 text-white hover:bg-gray-600"
          onClick={() => {
            setShowModal(null);
          }}
        >
          Cancel
        </button>
        <button
          className="text-sm bg-blueprimary-500 rounded-md shadow-md px-2 py-1 text-white hover:bg-blueprimary-600"
          type="submit"
        >
          Create User
        </button>
      </div>
    </form>
  );
};
export default AddUser;
