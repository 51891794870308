import { useContext, useMemo, useState, useEffect } from "react";
import { StateContext } from "../contexts/State";

export default function DateCellWrapper({ event, value, children, date, restrictedDays, ...props }) {
  // const { state, dispatch } = useContext(StateContext);
  const [isRestricted, setIsRestricted] = useState(false);

  var dateString = value.toISOString().split("T")[0];
  useEffect(() => {
    if (restrictedDays.includes(dateString)) {
      setIsRestricted(true);
    }
  }, [])
  // return children;
  return (
    <div className={`contents ${isRestricted ? "restricted" : ""}`} >
      {children}
    </div>
  )

  // return (
  //   <div>
  //     {children.map(child => (
  //       <p>hi</p>
  //     ))}
  //   </div>
  // )
}
