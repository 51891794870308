import React from "react";

const PlaySVG = ({ className, ...props }) => {
  return (
    <svg
      {...props}
      className={`${className}`}
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1697 14.1744C13.4966 16.5282 10.1562 18.6457 6.48026 20.0857C3.35196 21.2845 0.731603 19.7895 0.347163 16.7919C-0.12085 12.3732 -0.110564 8.14316 0.347163 4.19439C0.766318 1.07938 3.65283 -0.243128 6.48026 0.914376C10.0996 2.35563 13.3462 4.31189 16.1697 6.82565C18.5817 8.94941 18.6383 11.9682 16.1697 14.1744Z"
        fill="#273952"
      />
    </svg>
  );
};
export default PlaySVG;
