export const trainerReducer = (state, action) => {
  switch (action.type) {
    case "STORE_TRAINER_DATA":
      return action.payload;
    default:
      return state;
  }
};

export const currentUserReducer = (state, action) => {
  switch (action.type) {
    case "STORE_USER_DATA":
      return action.payload;
    default:
      return state;
  }
};
