import React, { useState } from "react";

/**
 * Content of the modal for deleting a user on the admin page.
 * Can either delete a user, or cancel (close the modal.)
 * @param selectedUser The user to be deleted.
 */

const ConfirmDeleteUser = ({
  token = "",
  selectedUser = {},
  setUsers = () => {},
  setShowModal = {},
  searchRef = null,
}) => {
  let [showError, setShowError] = useState("");

  async function deleteUser() {
    try {
      const res = await fetch(
        process.env.REACT_APP_API_URL + "users/delete/" + selectedUser.id,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const data = await res.json();
      if (!res.ok) throw new Error(data.error);
      setUsers((prev) => {
        const index = prev.findIndex((e) => e.id === selectedUser.id);
        prev.splice(index, 1);
        return [...prev];
      });
      setShowModal(null);
      searchRef.current.value = "";
    } catch (e) {
      console.error(e);
      setShowError(e.message);
    }
  }

  return (
    <div>
      <h4>
        Are you sure you want to <b>delete</b> {selectedUser.email}? <br />
        This action cannot be undone.
      </h4>
      {showError && (
        <span className="block w-fit text-redprimary-500 mx-auto">
          {showError}
        </span>
      )}
      <div className="mt-4 flex items-center justify-between">
        <button
          className="text-sm bg-blueprimary-500 rounded-md shadow-md px-2 py-1 text-white hover:bg-blueprimary-600"
          onClick={() => {
            setShowModal(null);
          }}
        >
          Cancel
        </button>
        <button
          className="text-sm bg-redprimary-500 rounded-md shadow-md px-2 py-1 text-white hover:bg-redprimary-600"
          onClick={deleteUser}
        >
          Delete User
        </button>
      </div>
    </div>
  );
};
export default ConfirmDeleteUser;
