import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import { StateContext } from "../../contexts/State";
import PlusSVG from ".././svg/PlusSVG";
import SearchBar from ".././SearchBar";

const ImportAttendees = ({
  goBack = () => {},
  setAttendees = () => {},
  setCapacity = () => {},
}) => {
  const { state } = useContext(StateContext);

  const [myInterventions, setMyInterventions] = useState([]);
  const [myFilteredInterventions, setMyFilteredInterventions] =
    useState(myInterventions);
  const [loading, setLoading] = useState(false);
  const controller = useRef();

  useEffect(() => {
    setMyFilteredInterventions(myInterventions);
  }, [myInterventions]);

  const getMyInterventions = useCallback(
    async (signal) => {
      try {
        setLoading(true);
        const data = await fetch(
          process.env.REACT_APP_API_URL + "interventions/me",
          {
            signal: signal,
            headers: {
              Authorization: "Bearer " + state.currentUser.token,
            },
          }
        );
        const res = await data.json();
        const { my_interventions } = res;
        setMyInterventions(my_interventions);
        setLoading(false);
      } catch (error) {
        console.error("error getting interventions: ", error);
        setLoading(false);
      }
    },
    [state.currentUser.token]
  );

  useEffect(() => {
    if (controller.current) {
      setLoading(false);
      controller?.current.abort();
    }

    controller.current = new AbortController();
    const { signal } = controller.current;
    getMyInterventions(signal);
    const unmountController = new AbortController();
    return () => {
      unmountController?.abort();
      console.log("aborting call...");
    };
  }, [getMyInterventions]);

  return (
    <section>
      {loading ? (
        <div className="m-auto text-center">
          <button
            onClick={goBack}
            className="flex items-center gap-2 shadow-md mt-2 px-2 py-1 rounded-md bg-blueprimary-500 text-white text-base hover:bg-blueprimary-600"
          >
            Go Back
          </button>
          <p className=" p-4">Loading your past attendees, hang tight...</p>
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between gap-2">
            <button
              onClick={goBack}
              className="flex items-center gap-2 shadow-md mt-2 px-2 py-1 rounded-md bg-blueprimary-500 text-white text-base hover:bg-blueprimary-600"
            >
              Go Back
            </button>
            <p className="font-semibold text-gray-800">Import Attendees</p>
            <button className="invisible">Go Back</button>
          </div>
          <p className="text-gray-500 text-sm mt-4">
            Listed below are all of your past intervention attendees.
          </p>
          <p className="text-gray-500 text-sm">
            Select one to import those attendees into your new intervention!
          </p>
          <SearchBar
            className="attendees-searchbar w-fit my-2 rounded-md shadow-md"
            placeholder="Search Interventions..."
            searchFields={["name"]}
            inputArray={myInterventions}
            updateArray={(newArray) =>
              setMyFilteredInterventions([...newArray])
            }
          />
          {myFilteredInterventions
            ?.filter((intervention) => intervention?.attendees?.length > 0)
            .map((intervention, key) => (
              <button
                key={key}
                type="button"
                className="w-full flex gap-2 justify-between items-center mt-5 p-4 rounded-md shadow-md  bg-gray-200 hover:bg-blue-100"
                onClick={() => {
                  setAttendees(intervention?.attendees);
                  setCapacity(intervention?.attendees.length);
                  goBack();
                }}
              >
                <div key={key} className="flex flex-col text-left">
                  <p className="font-semibold mb-2 text-gray-800">
                    {intervention.name}
                  </p>
                  {intervention.attendees
                    .sort((a, b) => {
                      if (a.last_name.toLowerCase() > b.last_name.toLowerCase())
                        return 1;
                      if (a.last_name.toLowerCase() < b.last_name.toLowerCase())
                        return -1;
                      return 0;
                    })
                    .map((attendee, key) => (
                      <p key={key} className="text-base">
                      {attendee.last_name}, {attendee.first_name}
                      </p>
                    ))}
                </div>
                <PlusSVG className="text-greenprimary-500" />
              </button>
            ))}
        </>
      )}
    </section>
  );
};
export default ImportAttendees;
